import {Howl} from 'howler';

class Player {
  constructor(url) {
    this.audio = new Howl({
      src: [url]
    });
  }

  play() {
    this.audio.play();
  }

  pause() {
    this.audio.pause();
  }
}

export default Player;
