function isEmptyObj(obj) {
  return Object.keys(obj).length === 0;
}

function padNumber(num, size) {
  let s = String(num);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}

function stringToTimeFormat(str) {
  return str.match(/.{1,2}/g);
}

function timeFormatToInt(timeVal) {
  return parseInt(timeVal.split(":").join(""))
}

function splitArrayInEqualsPart(arr, size) {
  const result = new Array(Math.ceil(arr.length / size)).fill().map(() => arr.splice(0, size))
  return result
}

function strIsNumeric(value) {
  return /^\d+$/.test(value);
}

function randomInteger(min, max) {
  //The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min)) + min;
}

function equalsIgnoreOrder(a, b) {
  if (a.length !== b.length) return false;
  const uniqueValues = new Set([...a, ...b]);
  for (const v of uniqueValues) {
    const aCount = a.filter(e => e === v).length;
    const bCount = b.filter(e => e === v).length;
    if (aCount !== bCount) return false;
  }
  return true;
}

export default {
  isEmptyObj,
  padNumber,
  stringToTimeFormat,
  timeFormatToInt,
  splitArrayInEqualsPart,
  strIsNumeric,
  randomInteger,
  equalsIgnoreOrder
}
