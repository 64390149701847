import Vue from 'vue'
import VueRouter from 'vue-router'
import GameRoutes from '@/modules/Game/Routes'

Vue.use(VueRouter);

let routes = [];

routes = routes.concat(GameRoutes)

const router = new VueRouter({
    routes,
})

export default router;

