<template>
  <footer>
    <div class="pg-footer">
      <div v-if="isOpenZoom" class="pg-zoom-open">
        <span class="">{{ scales[scaleSelected] }}%</span>
        <span ref="pg_zoom_in_btn" class="pg-btn-zoom-in" :aria-label='$t("st_footer_zoom_in")'
          tabindex="0" role="button" aria-pressed="false"
          @click="zoomIn()"
          @keyup.enter="zoomIn()"
          @keyup.space="zoomIn()">
          <span class="icon icon-plus"></span>
        </span>
        <span class="pg-btn-zoom-out" :aria-label='$t("st_footer_zoom_out")'
          tabindex="0" role="button" aria-pressed="false"
          @click="zoomOut()"
          @keyup.enter="zoomOut()"
          @keyup.space="zoomOut()">
          <span class="icon icon-minus"></span>
        </span>
        <span class="" :aria-label='$t("st_footer_zoom_reset")'
          tabindex="0" role="button" aria-pressed="false"
          @click="zoomReset()"
          @keyup.enter="zoomReset()"
          @keyup.space="zoomReset()">
          {{ $t("st_footer_zoom_reset") }}
        </span>
        <span class="separator"></span>
        <span class="icon-zoom-in" :aria-label='$t("st_footer_zoom_close")'
          tabindex="0" role="button" aria-pressed="false"
          @click="closeZoom()"
          @keyup.enter="closeZoom()"
          @keyup.space="closeZoom()"></span>
      </div>
      <span v-else ref="pg_open_zoom_btn" class="pg-btn-zoom" :aria-label='$t("st_footer_zoom_open")'
        tabindex="0" role="button" aria-pressed="false"
        @click="openZoom()" 
        @keyup.enter="openZoom()"
        @keyup.space="openZoom()">
        <span class="icon-zoom-in"></span>
      </span>
      <div class="container-footer-img" tabindex="0" aria-label="Junior Achievement">
        <div :style="logoJAStyles" class="container-footer-img__img"></div>
      </div>
    </div>
  </footer>
</template>

<script>

  export default {
    name: "GameFooter",
    data() {
      return {
        scales: [100, 110, 125, 150, 175, 200],
        isOpenZoom: false,
        scaleSelected: 0
      }
    },
    computed: {
      logoJAStyles(){
        return {
          backgroundImage: `url(${this.getImageById('ja-logo')})`
        }
      },
    },
    methods: {
      openZoom() {
        this.isOpenZoom = true
        this.$nextTick(() => {
          this.$refs.pg_zoom_in_btn.focus()
        })
      },
      closeZoom() {
        this.isOpenZoom = false
        this.$nextTick(() => {
          this.$refs.pg_open_zoom_btn.focus()
        })
      },
      zoomIn() {
        if (this.scaleSelected < 5) {
          this.scaleSelected += 1
          this.applyZoom(this.scales[this.scaleSelected])
        }
      },
      zoomOut() {
        if (this.scaleSelected > 0) {
          this.scaleSelected -= 1
          this.applyZoom(this.scales[this.scaleSelected])
        }
      },
      zoomReset() {
        this.scaleSelected = 0
        this.applyZoom(this.scales[this.scaleSelected])
      },
      applyZoom(zoom) {
        this.setZoom(zoom)
        this.refreshScale()
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/game_footer.styl";
</style>
