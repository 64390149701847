import {dnd} from "../Core/DragAndDrop";

export default {
  computed: {
    draggableData() {
      return dnd.currentDrag
    },
    draggableSource() {
      return dnd.source
    },
    draggableElement() {
      return dnd.source.$el
    },
    draggableInProgress() {
      return dnd.inProgress
    },
    draggableTop() {
      return dnd.top
    },
  }
}
