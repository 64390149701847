import {mapActions, mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters({
      getScale: "ZoomModule/getScale",
      getZoom: "ZoomModule/getZoomValue"
    }),
  },
  methods: {
    ...mapActions({
      refreshScale: "ZoomModule/loadZoom",
      setZoom: "ZoomModule/setZoom"
    }),
  }
};
