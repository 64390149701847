<template>
  <fragment>
    <div role="region" class="draggable">
      <div class="draggable__container-item" v-for="drag in drags" :key="`crust-${drag.id}`">
        <div class="container-item-drag">
          <draggable
            :style="getDragStyle(drag)"
            :assistive-text="`${$t(drag.label)}`"
            :item="drag"
            :z="1000"
            :scale="scale"
            class="pizza-drag pizza-drag--crust"
            :class="{'selected-drag': drag.dropped, [`crust-${drag.label_crust}`]: true}"
            @onDragStart="onDragStart"
            @onDragEnd="onDragEnd"
            :id="`${drag.id}`">
            <div class="label-pizza-drag">{{ $t(drag.label) }}</div>
          </draggable>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>

  import { DragData } from "../../DataDragDrop"
  import GameMixinDrag from "../../Mixins/GameMixinDrag"
  import { Draggable } from "@/core/Components"

  export default {
    name: "CrustDragOptions",
    components: { Draggable },
    mixins: [ GameMixinDrag ],
    data(){
      return {
        drags: DragData.CRUST_DRAGS
      }
    },
    methods: {
      getDragStyle(drag){
        return {
          backgroundImage: `url(${this.getImageById(drag.image)})`
        }
      }
    },
    mounted() {}
  }
</script>

<style lang="stylus" scoped>
@import "drag_options.styl";
</style>
