<template>
  <div class="wrapper-page-base">
    <div class="wrapper-page-base__inner">
      <div class="wrapper-inner__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "MainPageBase",
    mounted(){
      this.refreshScale()
      window.addEventListener('resize', this.refreshScale)

      this.setActivityResources({
        data:'config/data/game.json',
        media:'config/media/game.json',
        moduleName: 'plentyOfPizza'
      })

    },
    destroyed() {
      this.removeActivityResources()
      window.removeEventListener('resize', this.refreshScale)
    }
  };
</script>

<style lang="stylus" scoped>
@import "~@/core/Styles/variables.styl"
@import "~@/core/Styles/mixins.styl"

.wrapper-page-base
  margin auto
  position absolute
  top 0
  bottom 0
  left 0
  right 0
  &__inner
    position relative
    width 100%
    padding-top unit(radio_value, '%')

.wrapper-inner__content
  position  absolute
  top 0
  left 0
  bottom 0
  right 0
  min-height 100%
  height 100%
  div
    width 100%
    height 100%

</style>
