import { mapActions, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      images: "GameManagerModule/getImages",
      videos: "GameManagerModule/getVideos",
      audios: "GameManagerModule/getAudios",
      getImageById: "GameManagerModule/getImageById",
      getAudioById: "GameManagerModule/getAudioById",
      getVideoById: "GameManagerModule/getVideoById",
      loadingGameManager: "GameManagerModule/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      setActivityResources: "GameManagerModule/setActivityResources",
      removeActivityResources: "GameManagerModule/removeActivityResources",
      setToppingsToResources: "GameManagerModule/setToppingsToResources"
    }),
  }
};
