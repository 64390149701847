import { GameManager } from "../Services";


const state = {
  liveMessage: '',
  resources: {},
  loading: false,
  percentage: 0,
  error: null,
  currentComponent: null
};

const getters = {
  getLiveMessage: (state) => {
    return state.liveMessage
  },
  getData: (state) => {
    return state.resources.data || []
  },
  getImages: (state) => {
    return state.resources.media?.images || []
  },
  getVideos: (state) => {
    return state.resources.media?.videos || []
  },
  getAudios: (state) => {
    return state.resources.media?.audios || []
  },
  getPercentage: (state) => {
    return state.percentage
  },
  getLoading: (state) => {
    return state.loading
  },
  getError: (state) => {
    return state.error
  },
  getCurrentComponent: (state) => {
    return state.currentComponent
  },
  getImageById: () => (id) => {
    return GameManager.getMediaById('image', id)
  },
  getAudioById: (state) => (id) => {
    const audio = state.resources.media.audios.find(sound => sound.id === id)
    return audio.src
  },
  getVideoById: (state) => (id) => {
    const video = state.resources.media.videos.find(videoMedia => videoMedia.id === id)
    return video.src
  }
};

const actions = {
  setActivityResources({commit}, resources) {
    commit('fetchActivityStart');

    return GameManager.load(resources).then(() => {
      commit('fetchActivityResponse', {
        media: GameManager.media,
        data: GameManager.data
      });
    }).catch(e => {
      commit('fetchActivityError', e)
    })
  },
  setToppingsToResources({commit}, toppings) {
    return GameManager.loadToppings(toppings).then(() => {
      commit('fetchActivityResponse', {
        media: GameManager.media,
        data: GameManager.data
      });
    }).catch(e => {
      commit('fetchActivityError', e)
    })
  },
  removeActivityResources({commit}) {
    commit('fetchActivityDestroy')
  },
  onSwapComponent({commit}, currentComponent) {
    commit('setCurrentComponent', currentComponent)
  },
  setLiveMessage({commit}, message) {
    commit('setLiveMessage', message);
  }
};

const mutations = {
  fetchActivityStart(state) {
    state.resources = {}
    startLoading(state)
  },
  fetchActivityResponse(state, resources) {
    state.resources = resources
  },
  fetchActivityError(state, error) {
    state.error = error
    state.resources = {}
  },
  fetchActivityDestroy(state) {
    state.resources = {}
    state.loading = false
    state.percentage = 0
    state.error = null
  },
  setCurrentComponent(state, currentComponent) {
    state.currentComponent = currentComponent
  },
  setLiveMessage(state, liveMessage) {
    state.liveMessage = liveMessage
  }
};

const startLoading = (state) => {
  state.loading = true
  state.percentage = 0

  const incrementOrFinishLoading = () => {
    if (state.percentage < 100) {
      state.percentage += 0.3
    } else {
      finishLoading(state)
      clearInterval(fauxProgress);
    }
  }
  const fauxProgress = setInterval(incrementOrFinishLoading, 50);
}

const finishLoading = (state) => {
  state.percentage = 100
  setTimeout(() => {
    state.loading = false;
  }, 1500)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
