<template>
  <div ref="scrollWrapper"
       @click="calculateSize"
       :class="scrollbarWrapperClasses"
       :style="scrollWrapperStyles">

    <div ref="scrollArea"
         :class="scrollbarAreaClasses"
         @wheel="scroll"
         @touchstart="startDrag"
         @touchmove="onDrag"
         @touchend="stopDrag"
         :style="scrollAreaStyles">

        <slot></slot>

      <vertical-scrollbar
          v-if="ready"
          :area="scrollAreaObj"
          :wrapper="scrollWrapperObj"
          :scrolling="vMovement"
          :dragging-from-parent="dragging"
          :on-change-position="handleChangePosition"
          :on-dragging="handleScrollbarDragging"
          :on-stop-drag="handleScrollbarStopDrag"
          :scale="scale"
          ref="verticalScroll">
      </vertical-scrollbar>
    </div>
  </div>

</template>


<script>
  import VerticalScrollbar from './VerticalScrollbar.vue';

  export default {
    props: {
      classes: Object,
      styles: Object,
      speed: {
        type: Number,
        default: 53
      },
      onMaxScroll: Function,
      scale: {
        type: Number,
        default: 1,
      },
      scrollWrapperHeightProp: {
        type: Number,
        default: 0,
      },
      scrollAreaHeightProp: {
        type: Number,
        default: 0,
      }
    },
    components: { VerticalScrollbar },
    data () {
      return {
        ready: false,
        top: 0,
        left: 0,
        scrollAreaHeight: null,
        scrollAreaWidth: null,
        scrollWrapperHeight: null,
        scrollWrapperWidth: null,
        vMovement: 0,
        hMovement: 0,
        dragging: false,
        start: {y: 0, x: 0},
        allowBodyScroll: false,
      }
    },
    computed: {
      scrollWrapperStyles(){
        return {...this.styles, ...{
          height: `${this.scrollWrapperHeightProp}px`
        }}
      },
      scrollAreaStyles(){
        return {
          marginTop: `${this.top * -1}px`,
          marginLeft: `${this.left * -1}px`
        }
      },
      scrollbarWrapperClasses() {
        let classes = {
          'vue-scrollbar__wrapper': true,
        }
        return { ...classes, ...this.classes }
      },
      scrollbarAreaClasses() {
        let classes = {
          'vue-scrollbar__area': true,
        }
        classes['vue-scrollbar-transition'] = !this.dragging
        return classes
      },
      scrollAreaObj(){
        return {
          height: this.scrollAreaHeight
        }
      },
      scrollWrapperObj(){
        return {
          height: this.scrollWrapperHeight
        }
      }
    },
    methods: {
      scroll(e){
        this.onScrollEvent(false, e)
      },
      onScrollEvent(fromParent, e, heightT, deltaY){
        this.calculateSize(() => {
          let num = fromParent ? heightT : this.speed

          // DOM events
          let shifted = fromParent ? false : e.shiftKey
          let scrollY = (fromParent ? deltaY : e.deltaY) > 0 ? num : -(num)

          // Next Value
          let nextY = this.top + scrollY

          // Is it Scrollable?
          let canScrollY = this.scrollAreaHeight > this.scrollWrapperHeight

          // Vertical Scrolling
          if (canScrollY && !shifted) this.normalizeVertical(nextY)
        })

        // prevent Default only if scrolled content is not at the top/bottom
        if (!fromParent && !this.allowBodyScroll) {
          e.preventDefault()
          e.stopPropagation()
        }
      },
      // DRAG EVENT JUST FOR TOUCH DEVICE~
      startDrag(e){
        this.touchEvent = e
        const evt = e.changedTouches ? e.changedTouches[0] : e
        // Make sure the content height is not changed
        this.calculateSize(() => {
          this.dragging = true,
          this.start = {y: evt.pageY, x: evt.pageX}
        })
      },
      onDrag(e){
        if (this.dragging) {
          e.preventDefault()
          e.stopPropagation()
          // Prevent Click Event When it dragging
          if (this.touchEvent) {
            this.touchEvent.preventDefault()
            this.touchEvent.stopPropagation()
          }
          let evt = e.changedTouches ? e.changedTouches[0] : e
          let yMovement = this.start.y - evt.clientY

          // Update the last e.client
          this.start = {y: evt.clientY, x: evt.clientX}

          // The next Vertical Value will be
          let nextY = this.top + yMovement

          this.normalizeVertical(nextY)
        }
      },
      stopDrag(){ /*params: e*/
        this.dragging = false
        this.touchEvent = false
      },
      normalizeVertical(next){
        let newNext = next
        const elementSize = this.getSize()
        // Vertical Scrolling
        const lowerEnd = (elementSize.scrollAreaHeight - elementSize.scrollWrapperHeight) / this.scale

        // Max Scroll Down
        const maxBottom = newNext > lowerEnd
        if (maxBottom) newNext = lowerEnd

        // Max Scroll Up
        const maxTop = newNext < 0
        if (maxTop) newNext = 0

        // Update the Vertical Value if it's needed
        const shouldScroll = this.top !== newNext
        this.allowBodyScroll = !shouldScroll

        if (shouldScroll) {
          this.top = newNext,
          this.vMovement = (newNext / elementSize.scrollAreaHeight * 100) * this.scale
          if (this.onMaxScroll && (maxTop || maxBottom)) {
            this.onMaxScroll({top: maxTop, bottom: maxBottom, right: false, left: false})
          }
        }
      },
      handleChangePosition(movement, orientation){
        // Make sure the content height is not changed
        this.calculateSize(() => {
          // Convert Percentage to Pixel
          let next = movement / 100
          if (orientation == 'vertical') this.normalizeVertical(next * this.scrollAreaHeight)
        })
      },
      handleScrollbarDragging(){
        this.dragging = true
      },
      handleScrollbarStopDrag(){
        this.dragging = false
      },
      getSize(){
        let $scrollArea = this.$refs.scrollArea
        let $scrollWrapper = this.$refs.scrollWrapper

        const scrollAreaHeight = this.scrollAreaHeightProp ? this.scrollAreaHeightProp : $scrollArea.children[0].clientHeight
        const scrollWrapperHeight = this.scrollWrapperHeightProp ? this.scrollWrapperHeightProp : $scrollWrapper.clientHeight

        let elementSize = {
          scrollAreaHeight: scrollAreaHeight * this.scale,
          scrollAreaWidth: $scrollArea.children[0].clientWidth * this.scale,
          scrollWrapperHeight: scrollWrapperHeight * this.scale,
          scrollWrapperWidth: $scrollWrapper.clientWidth * this.scale,
        }
        return elementSize
      },
      calculateSize(cb){
        if (typeof cb !== 'function') cb = null;
        let elementSize = this.getSize()

        if (elementSize.scrollWrapperHeight !== this.scrollWrapperHeight ||
            elementSize.scrollWrapperWidth !== this.scrollWrapperWidth ||
            elementSize.scrollAreaHeight !== this.scrollAreaHeight ||
            elementSize.scrollAreaWidth !== this.scrollAreaWidth) {

          // Scroll Area Height and Width
          this.scrollAreaHeight = elementSize.scrollAreaHeight;
          this.scrollAreaWidth = elementSize.scrollAreaWidth;

          // Scroll Wrapper Height and Width
          this.scrollWrapperHeight = elementSize.scrollWrapperHeight;
          this.scrollWrapperWidth = elementSize.scrollWrapperWidth;

          // Make sure The wrapper is Ready, then render the scrollbar
          this.ready = true
          return cb ? cb() : false
        }
        else return cb ? cb() : false
      },
//      jumpScrollOnFocus(position){
//        this.$refs.verticalScroll.jumpScroll(true, null, position)
//      }
    },
    mounted () {
      this.calculateSize()
    },
    watch: {
      'scale' () {
        this.calculateSize()
      }
    },
  }
</script>