import {http} from '@/http'

const resource = "/api/game";

export default {
  joinGame(data) {
    return http.post(`${resource}/join/`, data)
  },
  updateScore(data) {
    return http.post(`${resource}/AddResult/`, data)
  },
  startedAt(gameCode) {
    return http.get(`${resource}/${gameCode}/startedAt/`, )
  },
  results(gameCode, teamId) {
    return http.get(`${resource}/${gameCode}/${teamId}/results/`, )
  },
}
