class AppZoom {
  static fullHdWidth = 1280
  static fullHdHeight = 720

  constructor() {
    this.zoom = 100
    this.scale = 0
    this.translateX = 0
    this.translateY = 0

    const $body = document.querySelector('#newBody')

    $body.style.height = AppZoom.fullHdHeight + 'px'
    $body.style.width = AppZoom.fullHdWidth + 'px'
  }

  setZoom(zoom) {
    if (this.zoom >= 100 && this.zoom <= 200) {
      this.zoom = zoom
    }
  }

  getZoom() {
    return this.zoom
  }

  getScale() {
    return this.scale
  }

  refreshScale() {
    const currentZoom = this.zoom
    const $mainBody = document.getElementById('body')

    if (currentZoom === 100) {
      $mainBody.style.overflow = 'hidden'
      $mainBody.scrollTo(0, 0);
      window.scrollTo(0, 0);

    } else {
      $mainBody.style.overflow = 'auto'
    }

    const windowWidth = document.documentElement.clientWidth
    const windowHeight = document.documentElement.clientHeight

    const widthScale = windowWidth / AppZoom.fullHdWidth
    const heightScale = windowHeight / AppZoom.fullHdHeight

    this.scale = Math.round(Math.min(widthScale, heightScale) * currentZoom) / 100

    let translateX = (windowWidth - AppZoom.fullHdWidth * this.scale) / 2
    let translateY = (windowHeight - AppZoom.fullHdHeight * this.scale) / 2

    this.translateX = translateX <= 0 ? 0 : translateX
    this.translateY = translateY <= 0 ? 0 : translateY

    let $newBody = document.getElementById('newBody')
    $newBody.style.transform = `translate(${this.translateX}px, ${this.translateY}px) scale(${this.scale})`
    $newBody.style.transformOrigin = "0 0"


    $newBody = document.getElementById('newBody')
    const newBody = $newBody.getBoundingClientRect()
    $mainBody.style.height = `${newBody.height}px`
    $mainBody.style.width = `${newBody.width}px`

  }
}

export default AppZoom
