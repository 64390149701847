<template>
  <header class="elem-inline-block">
    <div class="header__inner">
      <div class="header-item item-pizzas-correct" tabindex="0"
           :aria-label="`${correctPizzas} ${$t('st_header_correct_pizzas')}`"
           :class="{'centered-content': pizzasCorrectTextIsLarge}">
        <span class="elem-inline-block blue-text-header">{{ correctPizzas }}</span>
        <span class="elem-inline-block label-text-header">{{ $t("st_header_correct_pizzas") }}</span>
        <span class="elem-inline-block icon-ok-circled" :class="{'has-pizzas-correct': correctPizzas > 0}"></span>
      </div>
      <!-- Pizza Timer -->
      <game-timer ref="gameTimer" :game="game" @onNextPlayer="doOnNextPlayer" @onShownNextPlayerModal="doShownNextPlayerModal"/>
      <!-- End Pizza Timer  -->
      <div class="header-item item-player-team" tabindex="0"
           :aria-label="`${$t('st_header_team')} ${player.teamId}`">
        <span class="elem-inline-block label-text-header">{{ $t("st_header_team") }}</span>
        <span class="elem-inline-block blue-text-header">{{ String(player.teamId).padStart(2, '0') }}</span>
      </div>
      <div class="header-item item-game-logo">
        <img :src="logoPlentyPizza" alt="Plenty Of Pizza" class="item-game-logo__img" tabindex="0">
      </div>
    </div>
  </header>
</template>

<script>
  import { mapGetters } from "vuex";
  import GameTimer from "./GameTimer.vue"

  export default {
    name: "GameHeader",
    components: { GameTimer },
    props: {
      game: {
        type: Object,
        required: true,
        default: null
      },
      player: {
        type: Object,
        required: true,
        default: null
      }
    },
    data() {
      return {}
    },
    computed: {
      ...mapGetters(["correctPizzas"]),
      logoPlentyPizza(){
        return this.getImageById('plenty-pizza-logo-small')
      },
      pizzasCorrectTextIsLarge(){
        const text = this.$t("st_header_correct_pizzas");

        return text.length > 14
      }
    },
    mounted(){
      this.$refs['gameTimer'].setInitialTimerVal()
    },
    methods:{
      doOnNextPlayer(){
        this.$emit("doOnNextPlayer")
      },
      doShownNextPlayerModal(){
        this.$emit("doShownNextPlayerModal")
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/game_header.styl";
</style>
