const STATE = {
  order: {
    ids: {},
    texts: {},
  },
  userOrder: {},
  newOrderShown: 1,  
  correctPizzas: 0,
  remakesPizzas: 0
};

export default STATE;
