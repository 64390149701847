<template>
  <modal-overlay ref="nextPlayerOverlay">
    <custom-modal-box :title='$t("st_timer_next_player_title")'
                      :title2='$t("st_timer_next_player_message")'
                      :isNextPLayerModal="true"
                      @closeAction="reset">
      <div v-if="startCountDown">
        <div class="global-countdown-numbers">{{countDownTextAnimated}}</div>
      </div>
    </custom-modal-box>
  </modal-overlay>
</template>

<script>

  import { ModalOverlay, CustomModalBox } from "@/core/Components"

  export default {
    name: "NextPlayerModal",
    components: { ModalOverlay, CustomModalBox },
    props: {},
    data(){
      return {
        countDownText: "3...2...1...",
        countDownTextAnimated: "",
        intervalCountDown: null,
        startCountDown: false,
      }
    },
    methods: {
      setCountDown(){
        this.startCountDown = true;
        const text = this.countDownText.split("")
        const milliseconds = 3000/text.length

        let count = 1
        this.countDownTextAnimated = text[0]
        this.intervalCountDown = setInterval(() => {

          if(count == text.length){
            this.reset()
            return
          }

          this.countDownTextAnimated = this.countDownTextAnimated + text[count]

          count++;
        }, milliseconds)

      },
      clearIntervalCountDown(){
        clearInterval(this.intervalCountDown)
      },
      reset(){
        this.clearIntervalCountDown()
        this.$emit("resetGameTimer")
        this.startCountDown = false
      },
      showOverlay(){
        this.$refs["nextPlayerOverlay"].showOverlay()
        this.setCountDown()
      },
      hideOverlay(){
        this.$refs["nextPlayerOverlay"].hideOverlay()
      }
    },
    destroyed(){
      this.clearIntervalCountDown()
    }
  };
</script>

<style lang="stylus" scoped>
</style>
