<template>
  <div>
    <h1 tabindex="0" :aria-label="errorText" class="error-connection-text">
      {{ errorText }}
    </h1>
  </div>
</template>

<script>
  export default {
    name: "AppNoConnection",
    title: 'Connection Error',
    data() {
      return {
        errorText: "There was a connection error. Please refresh the page and try again"
      }
    }
  }
</script>