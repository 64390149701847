const TOPPING_SIZE = 80
const SQUARE_SIZE = 380
const RECTANGLE_WIDTH = 510
const RECTANGLE_HEIGHT = 300
const CIRCLE_SIZE = 380
const OVAL_WIDTH = 530
const OVAL_HEIGHT = 310

class Point {
  constructor(x, y) {
    this.x = x
    this.y = y
  }
}

function randomSquarePoints(count = 16) {
  return randomRectanglePointsBase(SQUARE_SIZE, SQUARE_SIZE, count)
}

function randomRectanglePoints(count = 16) {
  return randomRectanglePointsBase(RECTANGLE_WIDTH, RECTANGLE_HEIGHT, count)
}

function randomCirclePoints(count = 16) {
  const circle_radio = CIRCLE_SIZE / 2

  return randomOvalPointsBase(circle_radio, circle_radio, count)
}

function randomOvalPoints(count = 16) {
  const horizontal_radio = OVAL_WIDTH / 2
  const vertical_radio = OVAL_HEIGHT / 2

  return randomOvalPointsBase(horizontal_radio, vertical_radio, count)
}

function randomRectanglePointsBase(width, height, count) {
  const points = []

  for (let i=0; i < count; i++) {
    const maxWidth = width - TOPPING_SIZE
    const maxHeight = height - TOPPING_SIZE
    const x = randomBetweenMinAndMax(0, maxWidth)
    const y = randomBetweenMinAndMax(0, maxHeight)

    points.push(new Point(x, y))
  }

  return points
}

function randomOvalPointsBase(horizontalRadio, verticalRadio, count) {
  const points = []

  const hRadio = Math.round(horizontalRadio - TOPPING_SIZE / 2)
  const vRadio = Math.round(verticalRadio - TOPPING_SIZE / 2)

  for (let i=0; i < count; i++) {
    const r = hRadio * Math.sqrt(Math.random())
    const fi = 2 * Math.PI * Math.random()
    const x = Math.round(hRadio + r * Math.cos(fi))
    const y = Math.round(vRadio + vRadio / hRadio *  r * Math.sin(fi))

    points.push(new Point(x, y))
  }

  return points
}

function randomBetweenMinAndMax(min, max) {
  return Math.floor(Math.random() * (max + 1 - min)) + min
}

export {
  Point,
  randomSquarePoints,
  randomRectanglePoints,
  randomCirclePoints,
  randomOvalPoints
}
