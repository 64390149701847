<template>
  <div class="wrapper-big-box">
    <div class="wrapper-big-box__content" :style="boxContentStyles">
      <span class="elem-inline-block icon-cancel-circled"
            @click="closeAction" @keypress.prevent.space="closeAction" @keypress.prevent.enter="closeAction"
            tabindex="0" :aria-label="$t('st_close_popup_aria_label')"></span>

      <div v-if="isNextPLayerModal" role="alert" sr-only>
        {{ ariaLabelNextPlayer() }}
      </div>

      <h1 class="title-h1" :class="{ 'mb-0 pb-0': title2 }" tabindex="0" v-html="title" ></h1>
      <h2 class="title-h1 mt-0 pt-0" tabindex="0" v-html="title2" v-if="title2"></h2>

      <slot></slot>
    </div>
  </div>
</template>

<script>

  export default {
    name: "CustomModalBox",
    components: { },
    props: {
      title: {
        type: String,
        required: false,
        default: ''
      },
      title2: {
        type: String,
        required: false,
        default: ''
      },
      boxContentStyles: {
        type: Object,
        required: false,
        default: () => { return {} }
      },
      isNextPLayerModal: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data(){
      return {}
    },
    methods: {
      closeAction(){
        this.$emit("closeAction")
      },
      ariaLabelNextPlayer(){
        return `${this.$t('st_timer_next_player_title')} ${this.$t('st_timer_next_player_message')}`
      }
    },
    computed: {},
    mounted(){},
    destroyed() {},
  };
</script>

<style lang="stylus" scoped>
@import "~@/core/Styles/variables.styl"
@import "~@/core/Styles/mixins.styl"

.wrapper-big-box
  flexbox(flex)
  justify-content(center)
  align-items(center)
  padding-top 0px
  width 100%
  height 100%
  position relative
  
  & .elem-inline-block
    float right
    font-size 50pt
    color color-21
    margin -5px
    position relative
    z-index 2
    cursor pointer
  
  & .title-h1
    font-size 38pt
    margin 10px 0 0 0
    line-height 1
    padding-bottom 40px
    padding-top 72px
  
  &__content
    width 955px
    height 354px
    background-color color-10
    border 3px solid color-11
    text-align center
    border-radius(15px)
    box-shadow(big-box-shadow)
    position relative

  &.results-box
    & .elem-inline-block
      font-size 35pt
      position absolute
      right 0px
      top 0px

  &.results-box.content-multiline.winner-background &__content
    background-position-y -93px
    background-size 120% 125%
    .container-footer-results
      padding-top 35px
    & .winner-pizza-text
      background-size 100% 140px !important

  &.results-box &__content
    width 664px
    background-color color-10
    background-repeat no-repeat
    background-position center -32px
    background-size 115% 110%
    .container-results-block
      flexbox(flex)
      justify-content(space-between)
      padding 0px 5px
      .container-result-item
        width 241px
        height 169px
        background-repeat no-repeat
        background-position center
        background-size contain
        flexbox(flex)
        align-items(center)
    .container-footer-results
      min-height 90px
      margin-top -20px
      padding 20px 15px
      flexbox(flex)
      align-items(end)

    & .completed-pizza-text
    & .remake-pizza-text
      width 100%
      color color-10
      height 100%
      padding 30px 20px
      & .label
        font font-opensans-condensed-bold
        font-size 18px
        margin-bottom 0px
      & .global-countdown-numbers
        color color-10
        font-size 48px
    & .completed-pizza-text
      text-align right
      & .global-countdown-numbers
        text-align right 

    & .remake-pizza-text
      text-align left
      & .global-countdown-numbers
        text-align left

    & .winner-pizza-text
      text-align center
      width 100%
      color color-10
      font font-phoreuscherokee-bold
      font-size 24px
      height auto
      min-height 65px
      background-repeat no-repeat
      background-position center
      background-size cover
      flexbox(flex)
      align-items(center)
      position relative
      .winner-text
        width 90%
        margin auto
        padding 15px 0px
        word-break break-word
      .throphy-box
        position absolute
        width 150px
        height 150px
        background-repeat no-repeat
        background-position center
        background-size cover
        left calc(50% - 75px)
        top -136px

  &.winner-background &__content
  &.non-winner-background &__content
    .title-h1
      font-size 36px
      padding-bottom 0px
      padding-top 35px
      width inherit
  &.winner-background &__content
    min-height 325px
    height auto
  &.non-winner-background &__content
    min-height 270px
    height auto
    background-position-y 16px !important
</style>
