export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      drop: {}
    }
  },
  computed: {},
  methods: {
    onDropOpen(drop) {
      this.$emit('onDropOpen', {drop})
    },
    onDropLeave(dragLeave) {
      this.$emit('onDropLeave', dragLeave)
    },
    onDropCorrect(drop) {
      const selectedOptions = this.getSelectedOptionByDrop(drop.drag)
      const newDrop = {...drop, ...{selectedOptions: selectedOptions}}

      const isFinalized = this.validateIsFinalized(selectedOptions)

      this.drop = newDrop
      this.$emit('onDropCorrect', {drop: newDrop, isFinalized: isFinalized})
    },
    onDropIncorrect(){
      this.$emit('onDropIncorrect')
    },
    getSelectedOptionByDrop(drag) {
      let selectedOptions = {}
      for (const property in drag) {
        let options = drag[property].idOrderOption

        if(property === 'toppings'){

          const toppings = []
          for (const toppingProperty in drag[property]) {
            toppings.push(drag[property][toppingProperty].idOrderOption)
          }

          options = toppings
        }

        selectedOptions[property] = options
      }

      return selectedOptions ? selectedOptions : {}
    },
    validateIsFinalized(selectedOption){
      if(!selectedOption){
        return false
      }
      if(selectedOption.crust === undefined || selectedOption.crust === null){
        return false
      }
      if(selectedOption.sauce === undefined || selectedOption.sauce === null){
        return false
      }
      if(selectedOption.cheese === undefined || selectedOption.cheese === null){
        return false
      }
      if(selectedOption.toppings === undefined ||
          selectedOption.toppings === null ||
          !Array.isArray(selectedOption.toppings)){
        return false
      }

      if(selectedOption.toppings && selectedOption.toppings.length === 0){
        return false
      }

      return true
    }
  },
  watch: {
    item: {
      immediate: true,
      handler: function (value) {
        this.drop = {...value}
      }
    }
  },
  destroyed() {
    this.drop = {}
  },
}
