<template>
  <div role="region" class="draggable draggable-toppings" id="draggableToppings">
    <div class="draggable__container-item draggable__container-item--toppings-item"
         :id="`wrapper_drag_${drag.idOrderOption}`" v-for="drag in drags" :key="`toppings-drag-${drag.id}`">
      <div class="container-item-drag container-item-drag--topping">
        <div class="container-checkbox" :id="`checkboxContainer_${drag.idOrderOption}`">
          <b-form-checkbox @change="updateToppingByCheckbox($event, drag)"
                           :id="`toppingCheckbox_${drag.idOrderOption}`"
                           :data-topping-id="drag.id"></b-form-checkbox>
        </div>
        <draggable
            :style="getDragStyle(drag)"
            :assistive-text="`${$t(drag.label)}`"
            :item="drag"
            :z="1000"
            :scale="scale"
            class="pizza-drag pizza-drag--topping"
            :class="{'selected-drag': drag.dropped}"
            @onDragStart="onDragStart"
            @onDragEnd="onDragEnd"
            :id="`${drag.id}`">
          <div class="label-pizza-drag topping-label">{{ $t(drag.label) }}</div>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
  import GameMixinDrag from "../../Mixins/GameMixinDrag"
  import { Draggable } from "@/core/Components"

  export default {
    name: "ToppingsOptions",
    components: { Draggable },
    mixins: [ GameMixinDrag ],
    props: {
      toppings: {
        type: Array,
        required: true,
        default: () => {
            return []
        }
      },
    },
    data(){
      return {
        drags: this.toppings,
        checkDrag: true
      }
    },
    methods: {
      getDragStyle(drag){
        const imgName = `topping-preload-${drag.idOrderOption}`
        return {
          backgroundImage: `url(${this.getImageById(imgName)})`
        }
      },
      updateToppingByCheckbox($checked, drag){
        this.$emit("updateToppings", $checked, drag)
      },
      unCheckToppings(){
        const $checkboxList = document.querySelectorAll('*[id^="toppingCheckbox_"]')
        for (let i = 0; i < $checkboxList.length; i++){
          const $checkbox = $checkboxList[i]
          $checkbox.checked = false
        }
      },
      updateScrollOnToppingsTab(){
        const $checkboxList = document.querySelectorAll('*[id^="toppingCheckbox_"]')
        for (let i = 0; i < $checkboxList.length; i++){
          const $checkbox = $checkboxList[i]
          $checkbox.addEventListener("keyup", (e) => {
            if(e.keyCode == 9) {
              const toppingId = $checkbox.dataset.toppingId
              const $topping = document.getElementById(toppingId)
              this.callScrollbarFunction($topping)
            }
          });
        }

        const $toppingsList = document.querySelectorAll('*[id^="DA_TOPPINGS_"]')
        for (let i = 0; i < $toppingsList.length; i++){
          const $topping = $toppingsList[i]
          $topping.addEventListener("keyup", (e) => {
            if(e.keyCode == 9) {
              this.callScrollbarFunction($topping)
            }
          });
        }
      },
      callScrollbarFunction($topping){
        const collapsePosition = this.getCollapsePosition()
        const draggableToppingsPosition = this.getDraggableToppingsPosition()
        const toppingPosition = $topping.getBoundingClientRect()

        const scrollbarToppings = this.$refs.scrollbarToppings;
        let defaultHeight = 145
        let deltaY = (defaultHeight * this.scale) / this.scale;

        const conditional1 = draggableToppingsPosition.top == collapsePosition.top
        const conditional2 = toppingPosition.top < collapsePosition.top

        if(conditional2){
          deltaY *= -1
        }

        if(!conditional1 || conditional2){
          scrollbarToppings.onScrollEvent(true, null, defaultHeight, deltaY)
        }
      },
      getDraggableToppingsPosition(){
        const $containerToppings = document.getElementById("draggableToppings")
        return $containerToppings.getBoundingClientRect()
      },
      getCollapsePosition(){
        const $containerToppings = document.getElementById("toppings-collapse")
        return $containerToppings.getBoundingClientRect()
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import "drag_options.styl";
</style>
