<template>
  <page-base bg-name="login">
    <big-box :title='$t("st_lobbytitle")' 
             :title2='$t("st_lobby_plenty")'>
      <h2 class="title-h2" tabindex="0">{{ $t("st_youareon") }} {{ player.teamId }}.</h2>
      <div class="container-desc-screen" v-if="startCountDown">

        <div role="alert" sr-only>
          {{ $t("st_gamewillstart_arialabel") }}
        </div>

        <h3 class="desc-screen" tabindex="0">{{ $t("st_gamewillstart") }}</h3>
        <div class="countdown-numbers">{{countDownTextAnimated}}</div>
      </div>
      <div class="container-desc-screen" v-else>
        <h3 class="desc-screen" tabindex="0">{{ $t("st_pleasewaitgamestart") }}</h3>
      </div>
    </big-box>
  </page-base>
</template>

<script>
  import { GameConstants } from "@/core/Constants"
  import { PageBase } from "@/core/Components"
  import { BigBox } from "@/core/Components"

  export default {
    name: "LobbyPage",
    components: { PageBase, BigBox },
    props: {
      player: {
        type: Object,
        required: true,
        default: () => {
          return {
            teamId: null,
            gameId: null,
            student: null
          }
        }
      },
      gameStatus: {
        type: [String, Number],
        required: true,
        default: GameConstants.GAME_STATUS.CREATED
      }
    },
    data(){
      return {
        startCountDown: false,
        countDownText: "3...2...1...",
        countDownTextAnimated: "",
        intervalCountDown: null
      }
    },
    methods: {
      setCountDown(){
        const text = this.countDownText.split("")
        const milliseconds = 3000/text.length

        let count = 1
        this.countDownTextAnimated = text[0]
        this.intervalCountDown = setInterval(() => {
          this.countDownTextAnimated = this.countDownTextAnimated + text[count]
          count++;
          if(count == text.length+1){
            this.clearIntervalCountDown()
          }
        }, milliseconds)

      },
      clearIntervalCountDown(){
        clearInterval(this.intervalCountDown)
        this.$emit("updateGameStep", GameConstants.GAME_STEPS.GAME)
      }
    },
    watch: {
      gameStatus: function(status) {
        if(status == GameConstants.GAME_STATUS.IN_PROCESS){
          this.startCountDown = true
          this.setCountDown()
        }
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "../Styles/lobby.styl";
</style>
