<template>
  <div>
    <login v-if="gameStep == gameSteps.LOGIN"
           ref="loginPage"
           @joinGame="JoinGame"
           :showResetMessage="showResetMessage"
           :showLoginError="showLoginError" @resetLoginErrors="resetLoginErrors"></login>

    <lobby v-if="gameStep == gameSteps.LOBBY"
           @updateGameStep="updateGameStep" :player="player" :game-status="game.status"></lobby>

    <game-app v-if="gameStep == gameSteps.GAME" :game-data="game" :player-data="player" :global-setup="globalSetup"></game-app>
  </div>
</template>

<script>
  import { Game } from "@/core/Services"
  import { Storage } from "@/core/Utils"
  import { GameConstants } from "@/core/Constants"
  import Login from "@/modules/Login/Pages/Login.vue"
  import Lobby from "@/modules/Lobby/Pages/Lobby.vue"
  import GameApp from "./Game.vue"

  export default {
    name: "BaseGamePage",
    components: { Login, Lobby, GameApp },
    data(){
      return {
        game: {},
        globalSetup: {},
        player: {},
        gameStep: null,
        gameSteps: GameConstants.GAME_STEPS,
        showResetMessage: false,
        showLoginError: false,
        userHasConnection: false
      }
    },
    mounted () {
      this.mountPizzaGame()
      this.$gameHub.$on('game-start', this.onGameStarted)
      this.$gameHub.$on('game-reset', this.onGameReset)
    },
    methods: {
      onGameStarted({ gameStartedAt }) {
        if (this.game.status === GameConstants.GAME_STATUS.IN_PROCESS) return
        this.game.status = GameConstants.GAME_STATUS.IN_PROCESS

        //update localstorage data
        let localGame = Storage.getPizzaGameData()
        localGame.game.status = GameConstants.GAME_STATUS.IN_PROCESS
        localGame.game.startedAt = gameStartedAt
        Storage.setPizzaGameData(localGame)
      },
      onGameReset() {
        Storage.clearPizzaGameData()
        this.showResetMessage = true
        this.updateGameStep(this.gameSteps.LOGIN)
      },
      updateGameStep(step){
        this.gameStep = step
      },
      JoinGame(data){
        const localGame = Storage.getPizzaGameData()
        if(localGame.game){
          return
        }
        this.showResetMessage = false
        const gameCode = data.gameCode

        Game.joinGame(data).then((response) => {
          this.$gameHub.joinGame(gameCode)
          this.game = response.game ? response.game : {}
          this.globalSetup = response.globalSetup ? response.globalSetup : {}
          this.player = response.player ? response.player : {}

          this.preloadToppings()

          const gameData = {
            game: response.game,
            player: response.player,
            globalSetup: response.globalSetup
          }

          Storage.setPizzaGameData(gameData)
          this.updateGameStep(this.gameSteps.LOBBY)
          this.userHasConnection = true
          this.showLoginError = false
        }).catch(() => {
          this.showLoginError = true
          this.$refs["loginPage"].resetCodeText()
        })
      },
      mountPizzaGame(){
        const localGame = Storage.getPizzaGameData()

        if(localGame && localGame.game && localGame.player){
          this.player = localGame.player
          this.game = localGame.game
          this.globalSetup = localGame.globalSetup

          if(localGame.game.status == GameConstants.GAME_STATUS.IN_PROCESS){
            this.updateGameStep(this.gameSteps.LOGIN)
          }else{
            this.$gameHub.joinGame(this.game.gameCode)
            this.updateGameStep(this.gameSteps.LOBBY)
            this.preloadToppings()
          }
          return

        }else{
          this.updateGameStep(this.gameSteps.LOGIN)
        }
        return
      },
      preloadToppings(){
        let toppings = (this.globalSetup && this.globalSetup.toppings) ? this.globalSetup.toppings : []
        toppings = JSON.parse(JSON.stringify(toppings));

        const toppingsToPreload = toppings.map((topping) => {
          return {
            id: `topping-preload-${topping.id}`,
            src: topping.imageUrl
          }
        })

        this.setToppingsToResources(toppingsToPreload)
      },
      resetLoginErrors(){
        this.showLoginError = false;
        this.showResetMessage = false;
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
