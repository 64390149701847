<template>
  <div class="wrapper-game-overlay" :class="{'is-open': show, 'is-results-modal': isResultsModal}"  tabindex="0"
       v-if="show" :id="isOrderForm ? 'orderFormModal' : ''">
    <focus-loop>
      <div tabindex="0" class="skip-outline"></div>
      <slot></slot>
    </focus-loop>
  </div>
</template>

<script>
  import { FocusLoop } from '@vue-a11y/focus-loop'

  export default {
    name: "ModalOverlay",
    props: ['isOrderForm', 'isResultsModal'],
    components: { FocusLoop },
    data(){
      return {
        show: false
      }
    },
    methods: {
      showOverlay(){
        this.show = true
      },
      hideOverlay(){
        this.show = false;

        const $tagToFocus = document.querySelector('#orderFormModal.is-open .skip-outline')
        const $mainKipFocus = document.querySelector('#pageBaseSkipFocus')
        if($tagToFocus){
          $tagToFocus.focus()
        } else if($mainKipFocus) {
          $mainKipFocus.focus()
        }
      }
    }
  };
</script>

<style lang="stylus" scoped>
.wrapper-game-overlay
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 9998
  transition opacity .3s ease
  background-color rgba(0,0,0,0.6)
  &.is-results-modal
    z-index 9999
</style>
