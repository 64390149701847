/**
 * Common const to handle http requests and responses
 * src/http.js
 */
import axios from 'axios'
import qs from 'qs'

/**
 *
 * parse error response
 */
function parseError(messages) {
  // error
  if (messages) {
    if (messages instanceof Array) {
      return Promise.reject(new Error(messages[0]))
    }
    if (messages instanceof Object && messages.detail) {
      // JWT DRF returns an object error response
      return Promise.reject(messages.detail)
    } else {
      return Promise.reject(messages)
    }
  } else {
    return Promise.reject(new Error('Error'))
  }
}

/**
 * parse response
 */
function parseBody(response) {
  if (response.status === 200) {
    return response.data
  } else {
    return parseError(response.data.messages)
  }
}

/**
 * axios instance
 */
let instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  paramsSerializer: function (params) {
    return qs.stringify(params, {indices: false})
  }
})

// request header
instance.interceptors.request.use((config) => {
  // Do something before request is sent
  // api token
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    config.headers['Authorization'] = 'Bearer ' + user.token;
  }
  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return parseBody(response);
}, function (error) {
  let originalRequest = error.config
  const user = JSON.parse(localStorage.getItem('user'));
  if (error.response.status === 401 && user && !originalRequest._retry) {
    originalRequest._retry = true

    //TODO: call refresh token API
  }

  return parseError(error.response.data)
})

export const http = instance
