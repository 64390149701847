<template>
  <div class="container-order wrapper-options-bar">
    <div class="header-options-bar" tabindex="0" :aria-label="`${$t('st_order_make_your_pizza')}`">
      <span class="header-options-bar__text">{{ $t("st_order_make_your_pizza") }}</span>
    </div>
    <div class="accordion wrapper-accordion-section" role="tablist">
      <div class="container-option-bar">
        <div class="container-btn-collapse" role="tab">
          <button v-b-toggle.crust-collapse
                  class="common-btn-game btn-toggle-collapse"
                  :disabled="collapseIsDisabled(pizzaTabs.CRUST)">
            <div class="img-btn-toggle crust-img" :style="crustTabIconStyles"></div>
            <span>{{ $t("st_crust") }}</span>
          </button>
        </div>
        <b-collapse id="crust-collapse" v-model="tabsModel.crust" accordion="my-accordion" role="tabpanel">
          <div class="container-pizza-options">
            <crust-drag-options
              :scale="getScale"
              :items="crustDrags"
              @onDragStart="onDragStart"
            ></crust-drag-options>
          </div>
        </b-collapse>
      </div>
      <div class="container-option-bar">
        <div class="container-btn-collapse" role="tab">
          <button v-b-toggle.sauce-collapse
                  class="common-btn-game btn-toggle-collapse"
                  :disabled="collapseIsDisabled(pizzaTabs.SAUCE)">
            <div class="img-btn-toggle sauce-img" :style="sauceTabIconStyles"></div>
            <span>{{ $t("st_sauce") }}</span>
          </button>
        </div>
        <b-collapse id="sauce-collapse" v-model="tabsModel.sauce" accordion="my-accordion" role="tabpanel">
          <div class="container-pizza-options">
            <sauce-drag-options
              :scale="getScale"
              :items="sauceDrags"
              @onDragStart="onDragStart"
            ></sauce-drag-options>
          </div>
        </b-collapse>
      </div>
      <div class="container-option-bar">
        <div class="container-btn-collapse" role="tab">
          <button v-b-toggle.cheese-collapse
                  class="common-btn-game btn-toggle-collapse"
                  :disabled="collapseIsDisabled(pizzaTabs.CHEESE)">
            <div class="img-btn-toggle cheese-img" :style="cheeseTabIconStyles"></div>
            <span>{{ $t("st_cheese") }}</span>
          </button>
        </div>
        <b-collapse id="cheese-collapse" v-model="tabsModel.cheese" accordion="my-accordion" role="tabpanel">
          <div class="container-pizza-options">
            <cheese-drag-options
              :scale="getScale"
              :items="cheeseDrags"
              @onDragStart="onDragStart"
            ></cheese-drag-options>
          </div>
        </b-collapse>
      </div>
      <div class="container-option-bar">
        <div class="container-btn-collapse" role="tab">
          <button v-b-toggle.toppings-collapse
                  class="common-btn-game btn-toggle-collapse"
                  :disabled="collapseIsDisabled(pizzaTabs.TOPPINGS)">
            <div class="img-btn-toggle toppings-img" :style="toppingsTabIconStyles"></div>
            <span>{{ $t("st_toppings") }}</span>
          </button>
        </div>
        <b-collapse id="toppings-collapse" v-model="tabsModel.toppings" accordion="my-accordion" role="tabpanel">
            <div class="container-pizza-options cpo-toppings" v-if="showToppings">
              <toppings-options
                  ref="toppingsOptionsRef"
                  :scale="getScale"
                  :items="toppings"
                  :toppings="toppings"
                  @onDragStart="onDragStart"
                  @onDragEnd="onDragEnd"
                  @updateToppings="updateToppings"
              ></toppings-options>
            </div>
        </b-collapse>
      </div>
    </div>

    <div class="container-btn-make-pizza">
      <button class="btn-make-pizza" type="button" @click="bakePizza"
              :disabled="disabledBakeBtn" :aria-label="$t('st_order_bake')">
        <div class="img-make-pizza bake-pizza-img" :style="bakeBtnStyles"></div>
        <span>{{ $t("st_order_bake") }}</span>
      </button>
    </div>

    <bake-result-modal ref="bakeResultModal" :result="bakeResult" @onNextOrder="nextOrder" @onTryAgain="tryAgain"/>
  </div>
</template>

<script>

  import { mapGetters } from "vuex";
  import { DragData } from "../DataDragDrop"
  import { GameAppConstants } from "../Constants"
  import { Utils } from "@/core/Utils"
  import { GameConstants } from "@/core/Constants"
  import CrustDragOptions from"./DragOptions/CrustDragOptions.vue"
  import SauceDragOptions from"./DragOptions/SauceDragOptions.vue"
  import CheeseDragOptions from"./DragOptions/CheeseDragOptions.vue"
  import BakeResultModal from "./BakeResultModal.vue"
  import ToppingsOptions from"./DragOptions/ToppingsOptions.vue"

  export default {
    name: "PizzaOptionsBar",
    components: { CrustDragOptions, SauceDragOptions, CheeseDragOptions, BakeResultModal, ToppingsOptions },
    props: {
      toppingsList: {
          type: Array,
          required: true,
          default: () => {
            return []
          }
      },
    },
    data() {
      return {
        crustDrags: DragData.CRUST_DRAGS,
        sauceDrags: DragData.SAUCE_DRAGS,
        cheeseDrags: DragData.CHEESE_DRAGS,
        currentTab: GameAppConstants.PIZZA_TABS.CRUST,
        pizzaTabs: GameAppConstants.PIZZA_TABS,
        tabsModel: {
          crust: true,
          sauce: false,
          cheese: false,
          toppings: false,
        },
        disabledBakeBtn: true,
        showToppings: false
      }
    },
    computed: {
      ...mapGetters(["order", "userOrder"]),
      toppings(){

        const newToppings = this.toppingsList.map(( topping, index ) => {
          return {
            id: `DA_TOPPINGS_${index}`,
            label: topping.name,
            image: topping.imageUrl,
            idOrderOption: topping.id,
            isTopping: true,
            pizzaTabKey: 'toppings'
          }
        })

        return newToppings
      },
      bakeResult(){
        return this.isValidPizza()
      },
      crustTabIconStyles(){
        return {
          backgroundImage: `url(${this.getImageById('crust-tab-icon')})`
        }
      },
      sauceTabIconStyles(){
        return {
          backgroundImage: `url(${this.getImageById('sauce-tab-icon')})`
        }
      },
      cheeseTabIconStyles(){
        return {
          backgroundImage: `url(${this.getImageById('cheese-tab-icon')})`
        }
      },
      toppingsTabIconStyles(){
        return {
          backgroundImage: `url(${this.getImageById('toppings-tab-icon')})`
        }
      },
      bakeBtnStyles(){
        return {
          backgroundImage: `url(${this.getImageById('bake-pizza-btn-icon')})`
        }
      }
    },
    methods: {
      onDragStart(drag) {
        this.$emit("onDragStart", drag)
      },
      onDragEnd(drag) {
        this.$emit("onDragEnd", drag)
      },
      updateCurrentTab(tabId){
        this.currentTab = tabId
      },
      collapseIsDisabled(tabId){
        return tabId > this.currentTab
      },
      bakePizza(){
        if (this.bakeResult) {
          this.$store.dispatch('incrementCorrects');
        }
        else {
          this.$store.dispatch('incrementRemakes');
        }
        this.openBakeResultModal()
      },
      nextOrder(){
        this.resetUserPizza()
        this.$store.dispatch('newOrder');
        this.showToppingsContent(false)
      },
      tryAgain(){
        this.resetUserPizza()
        this.showToppingsContent(false)
      },
      resetUserPizza() {
        this.$store.dispatch('createUserOrder');
        this.updateCurrentTab(GameAppConstants.PIZZA_TABS.CRUST)
        this.$emit("resetDropData")
        this.toggleTabs()
        this.$emit("openOrderForm")
        this.unCheckToppings()
        this.setDisabledBakeBtn(true)
      },
      openBakeResultModal(){
        this.$refs["bakeResultModal"].showOverlay()
      },
      hideBakeResultModal(){
        this.$refs["bakeResultModal"].hideOverlay()
      },
      toggleTabs(){
        this.tabsModel = {
          crust: true,
          sauce: false,
          cheese: false,
          toppings: false,
        }
      },
      isValidPizza() {

        if ((this.userOrder.crust === undefined || this.userOrder.crust === null) ||
          this.order.ids.crust !== this.userOrder.crust) {
          return false
        }
        if ((this.userOrder.sauce === undefined || this.userOrder.sauce === null) ||
          this.order.ids.sauce !== this.userOrder.sauce) {
          return false
        }
        if (this.order.ids.cheese == GameConstants.NO_CHEESE) {
          if (this.userOrder.cheese && this.order.ids.cheese !== this.userOrder.cheese) {
            return false
          }
        }
        else {
          if ((this.userOrder.cheese === undefined || this.userOrder.cheese === null) ||
            this.order.ids.cheese !== this.userOrder.cheese) {
            return false
          }
        }

        if ((this.userOrder.toppings === undefined || this.userOrder.toppings === null) ||
          !Utils.equalsIgnoreOrder(this.order.ids.toppings, this.userOrder.toppings)) {
          return false
        }
        return true
      },
      updateToppings(add, drag){
        this.$emit("updateToppings", add, drag)
      },
      unCheckToppings(){
        if (this.showToppings) {
          this.$refs["toppingsOptionsRef"].unCheckToppings()
        }
      },
      setDisabledBakeBtn(isDisabled){
        this.disabledBakeBtn = isDisabled
      },
      showToppingsContent(show){
        this.showToppings = show
      }
    },
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/pizza_options_bar.styl";
</style>
