<template>
  <page-base bg-name="login">
    <big-box :title='$t("st_loadinggame")' :show-ja-logo="true">
      <div class="game-code-form">
        <form class="container-form">
          <div class="container-login-error">
            <div v-if="showLoginError || showResetMessage" role="alert" aria-live="polite" sr-only>
                {{ ariaLabelErrorLogin }}
            </div>
            <div class="login-error" id="loginError"
                 v-if="showLoginError || showResetMessage"
                 tabindex="0" :aria-label="ariaLabelErrorLogin">

              <span class="icon icon-attention"></span>
              <span class="message-error" v-if="showLoginError">{{ $t("st_invalid_code") }}</span>
              <span class="message-error" v-if="showResetMessage">{{ $t("st_reset_game_message") }}</span>
            </div>
          </div>

          <div class="game-code-form__inner">
            <label class="label-code-input" for="game_code"><span class="container-text-label">{{ $t("st_code") }}</span><span>:</span></label>
            <div id="wrapper_input" class="wrapper-input-code empty">
              <span class="entered-code">{{ gameCodeText }}</span>
              <span class="placeholder">{{ placeholderText }}</span>
              <input type="text"
                     id="game_code"
                     @focus="onFocusCode()"
                     @input="onInputCode($event)"
                     @blur="onBlurCode()"
                     class="input-code"
                     maxlength="6">
            </div>
          </div>
          <button type="button" class="common-btn" tabindex="0" :aria-label="$t('st_go')"
                  v-on:click="JoinGame"
                  :disabled="btnIsDisabled">{{ $t("st_go") }}</button>
        </form>
      </div>
    </big-box>
  </page-base>
</template>

<script>

  import { Storage } from "@/core/Utils"
  import { PageBase } from "@/core/Components"
  import { BigBox } from "@/core/Components"

  export default {
    name: "LoginPage",
    components: { PageBase, BigBox },
    props: {
      showResetMessage: {
        type: Boolean,
        required: true,
        default: false
      },
      showLoginError: {
        type: Boolean,
        required: true,
        default: false
      }
    },
    data(){
      return {
        gameCode: '',
        maxLengthCode: 6,
        placeholderCode: this.$t("st_code_placeholder"),
        gameCodeText: '',
        btnIsDisabled: true,
        placeholderText: ''
      }
    },
    mounted () {
      Storage.clearPizzaGameData()
      this.placeholderText = this.placeholderCode
    },
    methods: {
      JoinGame(){
        document.getElementById("game_code").blur();
        this.btnIsDisabled = true
        this.$emit("joinGame", { gameCode: this.gameCode })
      },
      onFocusCode() {
        this.$emit('resetLoginErrors')

        const wrapperInput = document.getElementById("wrapper_input");
        wrapperInput.classList.remove("empty")
        wrapperInput.classList.add("focus")
        if (this.gameCode=="") {
          this.placeholderCode = "XXXXXX"
          this.placeholderText = this.placeholderCode
        }
      },
      onInputCode($event) {
        let val = $event.target.value
        val = val.slice(0, this.maxLengthCode)
        $event.target.value = val;

        this.gameCodeText = val;
        this.gameCode = val;

        if (val != "") {
          this.placeholderText = this.placeholderCode.substring(this.maxLengthCode - val.length, 0)
        }
        else {
          this.placeholderText = this.placeholderCode
        }

        this.btnIsDisabled = val.length < this.maxLengthCode;
      },
      onBlurCode() {
        const wrapperInput = document.getElementById("wrapper_input");
        wrapperInput.classList.remove("focus")
        if (this.gameCode=="") {
          wrapperInput.classList.add("empty")
          this.placeholderCode = this.$t("st_code_placeholder")
          this.placeholderText = this.placeholderCode
        }
      },
      resetCodeText() {
        document.getElementById("game_code").value = ''
        const wrapperInput = document.getElementById("wrapper_input");
        wrapperInput.classList.remove("focus")
        wrapperInput.classList.add("empty")

        this.gameCode = "";
        this.gameCodeText = "";
        this.placeholderCode = this.$t("st_code_placeholder");
        this.placeholderText = this.placeholderCode;
        this.btnIsDisabled = true;
      }
    },
    computed: {
      ariaLabelErrorLogin(){
        let ariaLabel = ''
        if(this.showLoginError === true){
          ariaLabel = this.$t('st_invalid_code')
        }else if(this.showResetMessage === true){
          ariaLabel = this.$t('st_reset_game_message')
        }

        return ariaLabel
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "../Styles/login.styl";
</style>
