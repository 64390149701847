import { render, staticRenderFns } from "./CrustDragOptions.vue?vue&type=template&id=7a7faebd&scoped=true&"
import script from "./CrustDragOptions.vue?vue&type=script&lang=js&"
export * from "./CrustDragOptions.vue?vue&type=script&lang=js&"
import style0 from "./CrustDragOptions.vue?vue&type=style&index=0&id=7a7faebd&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a7faebd",
  null
  
)

export default component.exports