<template>
  <component :is="tag"
             :class="cssClasses"
             v-bind="attributeBindings"
             v-on="$listeners"
             :aria-label="getDropAriaLabel()">
    <slot></slot>
  </component>
</template>

<script>
import DroppableMixin from "../mixins/DroppableMixin";

export default {
  name: "Droppable",
  mixins: [DroppableMixin],
  methods: {
    getDropAriaLabel(){
      return this.$t(this.assistiveText)
    }
  }
}
</script>
