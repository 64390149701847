<template>
  <div class="wrapper-big-box">
    <div class="wrapper-big-box__content">
      <img src="@/core/Assets/PlentyOfPizza_Logo.png" alt="Plenty Of Pizza" class="big-box-logo" tabindex="0">
      <h1 class="title-h1" :class="{ 'mb-0': title2 }" tabindex="0" v-html="title"></h1>
      <h2 class="title-h1 mt-0" tabindex="0" v-html="title2" v-if="title2"></h2>
      <slot></slot>
      <div tabindex="0" aria-label="Junior Achievement" class="wrapper-big-box__content__img wrapper-big-box__content__img_2" v-if="showJaLogo"></div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "BigBox",
    components: { },
    props: {
      title: {
        type: String,
        required: false,
        default: ''
      },
      title2: {
        type: String,
        required: false,
        default: ''
      },
      showJaLogo: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data(){
      return {}
    },
    mounted(){},
    destroyed() {},
  };
</script>

<style lang="stylus" scoped>
@import "~@/core/Styles/variables.styl"
@import "~@/core/Styles/mixins.styl"

.wrapper-big-box
  flexbox(flex)
  justify-content(center)
  padding-top 70px
  width 100%
  height 100%
  position relative
  &__content
    width 495px
    height 525px
    background-color color-10
    border 3px solid color-11
    padding 27px 18px 14px 17px
    text-align center
    border-radius(15px)
    box-shadow(big-box-shadow)
    position relative
    .big-box-logo
      width 243px
      height 188px
    &__img
      position absolute
      bottom 14px
      width 128px
      height 40px
      left 14px
      background-position center
      background-repeat no-repeat
      background-image url("~@/core/Assets/logo-ja-full-color.svg")

</style>
