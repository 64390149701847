<template>
  <div class="container-order elem-inline-block">
    <modal-overlay ref="orderFormModal" :is-order-form="true">
      <div class="container-order wrapper-order-form">
        <div tabindex="0" v-if="newOrderShown" class="skip-outline"></div>
        <div>
          <div v-if="newOrderShown" class="container-order common-btn-game btn-order-form btn-on-modal"
               style="padding-top: 4px" :aria-label='$t("st_order_hide_aria_label")' tabindex="0">
            <span>{{ $t("st_order") }}</span>
            <span class="circle-btn-icon circle-btn-icon-on-modal"></span>
          </div>
          <button v-else @click="hideOrderForm" type="button"
                  class="container-order common-btn-game btn-order-form btn-on-modal"
                  :aria-label='$t("st_order_hide_aria_label")' :tabindex="newOrderShown ? '-1' : '0'">
            <span>{{ $t("st_order") }}</span>
            <span class="circle-btn-icon circle-btn-icon-on-modal"></span>
          </button>
        </div>
        <div class="wrapper-order-form__inner">
          <div class="container-requirements">
            <div class="item-requirement" tabindex="0"
                 :aria-label="`${ $t(order.texts.crust.key, {'option': $t(order.texts.crust.option_key)}) }`">
              <div class="item-requirement__img" :style="getOrderItemStyles(order.texts.crust.image)"></div>
              <div class="item-requirement__text">
                {{ $t(order.texts.crust.key, {'option': $t(order.texts.crust.option_key)}) }}
              </div>
            </div>
            <div class="item-requirement item-requirement--sauce" tabindex="0"
                 :aria-label="`${ $t(order.texts.sauce.key, {'option': $t(order.texts.sauce.option_key)}) }`">
              <div class="item-requirement__img" :style="getOrderItemStyles(order.texts.sauce.image)"></div>
              <div class="item-requirement__text">
                {{ $t(order.texts.sauce.key, {'option': $t(order.texts.sauce.option_key)}) }}
              </div>
            </div>
            <div class="item-requirement item-requirement--cheese" tabindex="0"
                 :aria-label="`${ $t(order.texts.cheese.key, {'option': $t(order.texts.cheese.option_key)}) }`">
              <div class="item-requirement__img" :class="{'cheese-no': order.texts.cheese.no_cheese == true}"
                   :style="getOrderItemStyles(order.texts.cheese.image)"></div>
              <div class="item-requirement__text">
                {{ $t(order.texts.cheese.key, {'option': $t(order.texts.cheese.option_key)}) }}
              </div>
            </div>
            <div v-for="(topping, key) in order.texts.toppings" :key="key" class="item-requirement item-requirement--toppings"
                 tabindex="0" :aria-label="`${ $t(topping.key) }`">
              <div class="item-requirement__img" :style="getOrderItemStyles(topping.image)"></div>
              <div class="item-requirement__text">
                {{ $t(topping.key) }}
              </div>
            </div>
          </div>
          <div class="container-btn-make-pizza" v-if="newOrderShown">
            <button class="btn-make-pizza" type="button" @click="makePizza" :aria-label='$t("st_order_make")'>
              <div class="img-make-pizza make-pizza-img" :style="makeBtnStyles"></div>
              <span>{{ $t("st_order_make") }}</span>
            </button>
          </div>
        </div>
      </div>
    </modal-overlay>
    <div>
      <button @click="openOrderForm" type="button"
              class="container-order common-btn-game btn-order-form"
              :aria-label='$t("st_order_show_aria_label")' tabindex="0">
        <span>{{ $t("st_order") }}</span>
        <span class="circle-btn-icon"></span>
      </button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { ModalOverlay } from "@/core/Components"

  export default {
    name: "OrderForm",
    components: { ModalOverlay },
    data(){
      return {}
    },
    computed: {
      ...mapGetters(["order", "newOrderShown"]),
      makeBtnStyles(){
        return {
          backgroundImage: `url(${this.getImageById('make-pizza-btn-icon')})`
        }
      }
    },
    mounted(){
      this.openOrderForm()
    },
    methods: {
      orderFormIsOpen(){
        return this.$refs["orderFormModal"].show
      },
      openOrderForm(){
        this.$refs["orderFormModal"].showOverlay()
      },
      hideOrderForm(){
        if (!this.newOrderShown) {
          this.$refs["orderFormModal"].hideOverlay()
        }
      },
      makePizza(){
        this.$store.dispatch('makePizza');
        this.$refs["orderFormModal"].hideOverlay()
        this.$emit("doAfterMakeClick")
      },
      getOrderItemStyles(imgKey){
        return {
          backgroundImage: `url(${this.getImageById(imgKey)})`
        }
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/order_form.styl";
</style>
