export default {
  CRUST_DRAGS: [
    {
      id: "DA_CRUST_0",
      label: "st_crust_oval",
      label_crust: "oval",
      image: "crust-oval",
      idOrderOption: 0,
      isCrust: true,
      pizzaTabKey: 'crust'
    },
    {
      id: "DA_CRUST_1",
      label: "st_crust_round",
      label_crust: "round",
      image: "crust-round",
      idOrderOption: 1,
      isCrust: true,
      pizzaTabKey: 'crust'
    },
    {
      id: "DA_CRUST_2",
      label: "st_crust_rectangular",
      label_crust: "rectangular",
      image: "crust-rectangular",
      idOrderOption: 2,
      isCrust: true,
      pizzaTabKey: 'crust'
    },
    {
      id: "DA_CRUST_3",
      label: "st_crust_square",
      label_crust: "square",
      image: "crust-square",
      idOrderOption: 3,
      isCrust: true,
      pizzaTabKey: 'crust'
    },
  ],
  SAUCE_DRAGS: [
    {
      id: "DA_SAUCE_0",
      label: "st_sauce_tomato",
      image: "sauce-tomato",
      imageDrop: "JAOC_POP_TomatoSauce_",
      idOrderOption: 0,
      isSauce: true,
      pizzaTabKey: 'sauce'
    },
    {
      id: "DA_SAUCE_1",
      label: "st_sauce_pesto",
      image: "sauce-pesto",
      imageDrop: "JAOC_POP_PestoSauce_",
      idOrderOption: 1,
      isSauce: true,
      pizzaTabKey: 'sauce'
    },
    {
      id: "DA_SAUCE_2",
      label: "st_sauce_white",
      image: "sauce-white_sauce",
      imageDrop: "JAOC_POP_WhiteSauce_",
      idOrderOption: 2,
      isSauce: true,
      pizzaTabKey: 'sauce'
    },
    {
      id: "DA_SAUCE_3",
      label: "st_sauce_squid",
      image: "sauce-squid",
      imageDrop: "JAOC_POP_SquidInkSauce_",
      idOrderOption: 3,
      isSauce: true,
      pizzaTabKey: 'sauce'
    },
  ],
  CHEESE_DRAGS: [
    {
      id: "DA_CHEESE_0",
      label: "st_cheese_mozzarella",
      image: "cheese-mozzarella",
      imageDrop: "JAOC_POP_MozzarellaCheese_",
      idOrderOption: 0,
      isCheese: true,
      pizzaTabKey: 'cheese'
    },
    {
      id: "DA_CHEESE_1",
      label: "st_cheese_cheddar",
      image: "cheese-cheddar",
      imageDrop: "JAOC_POP_CheddarCheese_",
      idOrderOption: 1,
      isCheese: true,
      pizzaTabKey: 'cheese'
    },
    {
      id: "DA_CHEESE_2",
      label: "st_cheese_aged",
      image: "cheese-aged",
      imageDrop: "JAOC_POP_AgedCheese_",
      idOrderOption: 2,
      isCheese: true,
      pizzaTabKey: 'cheese'
    },
    {
      id: "DA_CHEESE_3",
      label: "st_cheese_no",
      image: "cheese-no",
      imageDrop: "",
      idOrderOption: 3,
      isCheese: true,
      pizzaTabKey: 'cheese',
      isNoCheese: true
    },
  ],
}
