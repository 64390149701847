import { Utils, Storage } from "@/core/Utils"
import { DragData } from "@/modules/Game/DataDragDrop"

class PizzaOrder {

  constructor() {
    this.crust = null
    this.sauce = null
    this.cheese = null
    this.toppings = []
    this.crust_text = ''
    this.sauce_text = ''
    this.cheese_text = ''
    this.toppings_text = []
    this.createOrder()
  }

  setCrust() {
    const options = DragData.CRUST_DRAGS
    this.crust = Utils.randomInteger(0, options.length)
    const crust = options[this.crust]
    this.crust_text = {
      key: 'st_crust_text',
      option_key: crust.label,
      image: crust.image
    }
  }

  setSauce() {
    const options = DragData.SAUCE_DRAGS
    this.sauce = Utils.randomInteger(0, options.length)
    const sauce = options[this.sauce]
    this.sauce_text = {
      key: 'st_sauce_text',
      option_key: sauce.label,
      image: sauce.image
    }
  }

  setCheese() {
    const options = DragData.CHEESE_DRAGS
    this.cheese = Utils.randomInteger(0, options.length)

    const cheese = options[this.cheese]

    if (cheese.isNoCheese === true) {
      this.cheese_text = {
        key: cheese.label,
        option_key: '',
        image: cheese.image,
        no_cheese: true
      }
    } else {
      this.cheese_text = {
        key: 'st_cheese_text',
        option_key: cheese.label,
        image: cheese.image
      }
    }

  }

  setToppings() {
    const localGame = Storage.getPizzaGameData()

    if(localGame && localGame.globalSetup && localGame.game){
      const global_toppings = localGame.globalSetup.toppings
      const max_toppings = localGame.game.maxToppings
      const num_toppings = Utils.randomInteger(1, max_toppings+1)

      let i = 1;
      while (i <= num_toppings) {
        const topping_selected = Utils.randomInteger(0, global_toppings.length)
        if (!this.toppings.includes(global_toppings[topping_selected].id)) {
          const topping = global_toppings[topping_selected]
          this.toppings.push(topping.id)

          const topping_text = {
            key: topping.name,
            image: `topping-preload-${topping.id}`
          }

          this.toppings_text.push(topping_text)
          i++
        }
      }
    }
  }

  createOrder() {
    this.setCrust()
    this.setSauce()
    this.setCheese()
    this.setToppings()
  }

  getOrder() {
    const order = {
      'crust': this.crust,
      'sauce': this.sauce,
      'cheese': this.cheese,
      'toppings': this.toppings
    }
    return order
  }

  getOrderText() {
    const order = {
      'crust': this.crust_text,
      'sauce': this.sauce_text,
      'cheese': this.cheese_text,
      'toppings': this.toppings_text
    }
    return order
  }

}

export default PizzaOrder
