import AppZoom from '../Helpers/zoom'

const ApplicationZoom = new AppZoom()

const state = {
  zoom: 100,
  scale: 0
}

const getters = {
  getZoomValue: state => state.zoom,
  getScale: state => state.scale,
};

const actions = {
  setZoom({commit}, zoom) {
    ApplicationZoom.setZoom(zoom)
    ApplicationZoom.refreshScale()

    commit('SET_SCALE', {
      zoom: ApplicationZoom.getZoom(),
      scale: ApplicationZoom.getScale()
    });
  },
  loadZoom({commit}) {
    ApplicationZoom.refreshScale()

    commit('SET_SCALE', {
      zoom: ApplicationZoom.getZoom(),
      scale: ApplicationZoom.getScale()
    });
  }
};

const mutations = {
  SET_SCALE(state, dimensions) {
    state.scale = dimensions.scale;
    state.zoom = dimensions.zoom;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
