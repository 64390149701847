export const order = (state) => {
  return state.order;
}

export const userOrder = (state) => {
  return state.userOrder;
}

export const newOrderShown = (state) => {
  return state.newOrderShown;
}

export const correctPizzas = (state) => {
  return state.correctPizzas;
}

export const remakesPizzas = (state) => {
  return state.remakesPizzas;
}
