import Vue from 'vue'
import { CollapsePlugin, FormCheckboxPlugin  } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './core/Styles/main.styl'

import App from './App.vue'
import AppNoConnection from './AppNoConnection.vue'
import router from './router'
import store from './store'
import Fragment from 'vue-fragment'
import { TitleMixin, AppZoomMixin, GameManagerMixin } from './core/Mixins'
import { DebounceEventDirective } from './core/Directives'
import { GameHub, UtilsI18n } from './core/Helpers'

Vue.config.productionTip = false

Vue.use(CollapsePlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(GameHub)
Vue.use(Fragment.Plugin)
Vue.mixin(TitleMixin)
Vue.mixin(AppZoomMixin)
Vue.mixin(GameManagerMixin)
Vue.directive('debounce', DebounceEventDirective)

UtilsI18n.initI18n().then(function(i18n) {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}).catch(function () {
  const $body = document.getElementById("body")
  $body.classList.add('error-connection-page')

  new Vue({
    render: h => h(AppNoConnection)
  }).$mount('#app')

})
