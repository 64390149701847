<template>
  <div class="wrapper-pizza-drop-side">
    <droppable ref="droppableRef"
               :id="`droppable_${drop.id}`"
               :item="drop"
               class="drop-pizza"
               :assistive-text="drop.label"
               @onDropOpen="onDropOpen"
               @onDropCorrect="onDropCorrect"
               @onDropIncorrect="onDropIncorrect"
               @onDropLeave="onDropLeave">

      <div v-if="drop && drop.dropped" draggable="false" class="container-pizza-dropped">
        <div class="container-pizza-dropped__inner"
             :class="{[`item-drop-${drop.drag['crust'].label_crust}`]: true}"
             :style="getDropStyle(drop, 'crust')">
          <div class="container-pizza-dropped__sauce" :style="getDropStyle(drop, 'sauce')" v-if="drop.drag.sauce">
            <div class="container-pizza-dropped__cheese" :style="getDropStyle(drop, 'cheese')" v-if="drop.drag.cheese">
              <div class="container-toppings-area" v-if="drop.drag.toppings">
                <div class="container-toppings-area__inner">

                  <div v-for="(topping, indexTopping) in toppingsList(drop.drag.toppings, drop.drag.crust.label_crust)"
                       :key="`topping-drop-${indexTopping}`"
                       class="container-pizza-dropped__toppings" :style="getToppingStyle(topping)">
                    <draggable
                        assistive-text=""
                        :item="topping"
                        :scale="getScale"
                        :style="getToppingDragStyle(topping)"
                        :z="1000" class="topping-drag"
                        @onDragEnd="onDragEnd" @onDragStart="onDragStart">
                    </draggable>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </droppable>
  </div>
</template>

<script>
  import GameMixinDrop from "../Mixins/GameMixinDrop"
  import { DropData } from "../DataDragDrop"
  import { Droppable } from "@/core/Components"
  import { GameConstants } from "@/core/Constants"
  import { RandomToppings } from "../Utils"
  import { Draggable } from "@/core/Components"

  export default {
    name: "PizzaDropSide",
    components: { Droppable, Draggable },
    mixins: [ GameMixinDrop ],
    mounted() {
      this.element = this.$refs.droppableRef;
    },
    methods: {
      getRandomPoints(crustShape){
        let points = []
        if(crustShape === "oval"){
          points = RandomToppings.randomOvalPoints()
        }
        if(crustShape === "round"){
          points = RandomToppings.randomCirclePoints()
        }
        if(crustShape === "rectangular"){
          points = RandomToppings.randomRectanglePoints()
        }
        if(crustShape === "square"){
          points = RandomToppings.randomSquarePoints()
        }
        return points
      },
      getDropStyle(drop, dragKey){
        const drag = drop.drag[dragKey]
        let imgName = drag.image

        const isCrust = dragKey === 'crust'

        if(!isCrust){
          const labelCrust = drop.drag['crust'].label_crust
          imgName =`${drag.image}-${labelCrust}`

          if(drag.isCheese){
            if (drag.idOrderOption == GameConstants.NO_CHEESE) {
              return {
                backgroundImage: '',
              }
            }
          }
        }

        return {
          backgroundImage: `url(${this.getImageById(imgName)})`
        }
      },
      restartDropData(){
        this.drop = DropData.PIZZA_DROP
      },
      toppingsList(toppings, crustShape){
        let toppingsList = []

        for (const prop in toppings){
          const baseTopping = toppings[prop]
          const randomPoints = this.getRandomPoints(crustShape)

          randomPoints.forEach((point) => {
            let toopingDrag = {};

            toopingDrag.id = baseTopping.id
            toopingDrag.idOrderOption = baseTopping.idOrderOption
            toopingDrag.image = baseTopping.image
            toopingDrag.label = baseTopping.label
            toopingDrag.isToppingChild = true
            toopingDrag.pizzaTabKey = baseTopping.pizzaTabKey
            toopingDrag.isTopping = false

            toppingsList.push({...toopingDrag, ...{position: point}})
          })
        }
        return toppingsList
      },
      getToppingStyle(topping){
        return {
          top: `${topping.position.y}px`,
          left: `${topping.position.x}px`,
          transform: `rotate(${this.getRandomRotation()}deg)`
        }
      },
      getToppingDragStyle(topping){
        const imgName = `topping-preload-${topping.idOrderOption}`
        return {
          backgroundImage: `url(${this.getImageById(imgName)})`
        }
      },
      getRandomRotation(){
        const rotation = Math.floor(Math.random() * (360 - 0)) + 0;
        return rotation
      },
      updateDropZone(add, drag){
        this.$refs.droppableRef.doDroppableDrop(drag, add)
      },
      onDragEnd(drag, e){
        const $parentDrag = e.target.parentElement
        if($parentDrag){
          $parentDrag.classList.remove("not-rotate")
        }

        if(drag.leaved){
          this.updateDropZone(false, drag)
        }
      },
      onDragStart(drag){
        const $parentD = drag.event.target.parentElement
        $parentD.classList.add("not-rotate")
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/pizza_drop_side.styl";
</style>
