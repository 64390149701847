import BaseGameView from "../BaseGameView.vue";
import BaseGamePage from "../Pages/BaseGame.vue";

const routes = [
  {
    path: '',
    component: BaseGameView,
    children: [
      {
        path: '',
        name: 'GamePage',
        component: BaseGamePage,
      },
    ]
  }
]

export default routes
