import {
  NEW_ORDER,
  NEW_USER_ORDER,
  USER_ORDER_CHANGED,
  START_GAME,
  MAKE_PIZZA,
  INCREMENT_CORRECT_PIZZAS,
  INCREMENT_REMAKES_PIZZAS  
} from "@/modules/Game/Store/mutation-types";

export default {
  [NEW_ORDER](state, order) {
    state.newOrderShown = 1
    state.order = order;
  },
  [NEW_USER_ORDER](state) {
    state.userOrder = {};
  },
  [USER_ORDER_CHANGED](state, userOrder) {
    state.userOrder = { ...state.userOrder, ...userOrder }
  },
  [START_GAME](state) {
    state.correctPizzas = 0
    state.remakesPizzas = 0
  },
  [MAKE_PIZZA](state) {
    state.newOrderShown = 0
  },  
  [INCREMENT_CORRECT_PIZZAS](state) {
    state.correctPizzas += 1
  },
  [INCREMENT_REMAKES_PIZZAS](state) {
    state.remakesPizzas += 1
    state.newOrderShown = 1
  },
}
