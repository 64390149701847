<template>
  <div class="header-item item-game-duration" :class="{'not-enough-time': notEnoughTime}">
    <span class="elem-inline-block blue-text-header" tabindex="0"
          :aria-label="getTimerAriaLabel()">
      {{ gameDurationText }}
    </span>
    <span v-if="notEnoughTime" class="elem-inline-block game-duration-icon icon-attention"></span>
    <span v-else class="elem-inline-block game-duration-icon icon-clock"></span>
    <nextPlayerModal ref="nextPlayerModal" @resetGameTimer="resetGameTimer"/>
    <team-results-modal ref="teamResultsModal" :result="results"/>
  </div>
</template>

<script>
  import { Game } from "@/core/Services"
  import { Utils } from "@/core/Utils"
  import { GameConstants } from "@/core/Constants"
  import NextPlayerModal from "./NextPlayerModal.vue"
  import TeamResultsModal from "./TeamResultsModal.vue"
  import { Storage } from "@/core/Utils"

  export default {
    name: "GameTimer",
    components: { NextPlayerModal, TeamResultsModal },
    props: {
      game: {
        type: Object,
        required: true,
        default: null
      },
    },
    data() {
      return {
        gameDurationText: "00:00",
        gameDurationSec: 0,
        gameDurationMin: 0,
        gameDurationSecByStudent: 0,
        gameDurationMinByStudent: 0,
        notEnoughTime: false,
        studentsWhoAlreadyPlayed: 1,
        intervalCountDown: null,
        isSharedMode: false,
        durationArray: [],
        results: {}
      }
    },
    destroyed(){
      this.clearIntervalCountDown()
    },
    methods: {
      setGameDurationText(minTex, secText){
        this.gameDurationText = `${minTex}:${secText}`
      },
      setCurrentMinSec(min, sec){
        this.gameDurationMin = parseInt(min)
        this.gameDurationSec = parseInt(sec)
      },
      setInitialTimerVal(){
        this.isSharedMode = this.game.gameMode == GameConstants.GAME_MODE.SHARED

        this.durationArray = this.getTimeByStudent(this.game.gameDuration)
        this.setGameDurationText(this.durationArray[0], this.durationArray[1])
        this.setCurrentMinSec(this.durationArray[0], this.durationArray[1])

        if(this.gameDurationMin == 0 && this.gameDurationSec == 10){
          this.notEnoughTime = true
        }

        this.initCountDown()
      },
      initCountDown(){
        this.intervalCountDown = setInterval(() => {
          this.setCountDownAction()
        }, 1000)
      },
      setCountDownAction(){
        let min = this.gameDurationMin
        let sec = this.gameDurationSec

        if(sec == 0){
          min = min > 0 ? min - 1 : 0
          sec = 59
        }else{
          sec = sec - 1
          if(min == 0 && sec == 10){
            this.notEnoughTime = true
          }
        }

        this.setGameDurationText(Utils.padNumber(min, 2), Utils.padNumber(sec, 2))
        this.setCurrentMinSec(min, sec)

        if(min == 0 && sec == 0){
          this.clearIntervalCountDown()
          if(this.isSharedMode){
            if(this.studentsWhoAlreadyPlayed == this.game.maxStudentsPerTeam){
              this.showTeamResults()
              return
            }

            this.openNextPlayerModal()
            this.studentsWhoAlreadyPlayed++
            return
          }else{
            this.showTeamResults()
          }
        }
        return
      },
      getTimeByStudent(totalSecs){
        const minByStudent = Math.floor(totalSecs / 60);
        const secByStudent = parseInt(totalSecs  - minByStudent * 60);

        const totalTime = [Utils.padNumber(minByStudent, 2), Utils.padNumber(secByStudent, 2)]
        return totalTime

      },
      resetGameTimer(){
        //TODO: Here create order to new player
        this.doOnNextPlayer()
        this.notEnoughTime = false;
        this.setCurrentMinSec(this.durationArray[0], this.durationArray[1])
        this.setGameDurationText(Utils.padNumber(this.gameDurationMin, 2), Utils.padNumber(this.gameDurationSec, 2))
        this.hideNextPlayerModal()
        this.initCountDown()
      },
      clearIntervalCountDown(){
        clearInterval(this.intervalCountDown)
      },
      openNextPlayerModal(){
        this.$emit("onShownNextPlayerModal")
        this.$refs["nextPlayerModal"].showOverlay()
      },
      hideNextPlayerModal(){
        this.$refs["nextPlayerModal"].hideOverlay()
      },
      getTimerAriaLabel(){
        const ariaLabel = this.$t("st_timer_aria_label", {min: this.gameDurationMin, sec: this.gameDurationSec})
        return ariaLabel
      },
      doOnNextPlayer(){
        this.$store.dispatch('createUserOrder');
        this.$store.dispatch('newOrder');

        this.$emit("onNextPlayer")
      },
      showTeamResults(){
        /* PopUp: Results */
        const gameData = Storage.getPizzaGameData()
        Game.results(gameData.game.gameCode, gameData.player.teamId).then((response) => {
          if(response.success){
            this.results = response.result
            this.$refs["teamResultsModal"].showOverlay()
          }
        })
      },
    }
  };
</script>

<style lang="stylus" scoped>
  @import "Styles/game_header.styl";
</style>
