<template>
  <div>
    <div ref="container" class="vue-scrollbar__scrollbar-vertical" @click="jump" v-if="height < 100">
      <div ref="scrollbar"
        :class="scrollbarClasses"
        @touchstart="startDrag"
        @mousedown="startDrag "
        :style="scrollbarStyles">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      draggingFromParent: Boolean,
      scrolling: Number,
      wrapper: Object,
      area: Object,
      onChangePosition: Function,
      onDragging: Function,
      onStopDrag: Function,
      scale: {
        type: Number,
        default: 1,
      },
    },
    data () {
      return {
        height: 0,
        dragging: false,
        start: 0
      }
    },
    computed: {
      scrollbarClasses() {
        let classes = {
          'scrollbar': true,
        }
        classes['vue-scrollbar-transition'] = this.dragging || this.draggingFromParent

        return { ...classes }
      },
      scrollbarStyles(){
        return {
          height: `${this.height}%`,
          top: `${this.scrolling}%`
        }
      }
    },
    methods: {
      startDrag(e){
        e.preventDefault()
        e.stopPropagation()
        e = e.changedTouches ? e.changedTouches[0] : e

        this.dragging = true;
        this.start = e.clientY
      },
      onDrag(e){
        if (this.dragging) {
          // Make The Parent being in the Dragging State
          this.onDragging()
          e.preventDefault()
          e.stopPropagation()

          e = e.changedTouches ? e.changedTouches[0] : e
          let yMovement = e.clientY - this.start
          let yMovementPercentage = yMovement / this.wrapper.height * 100

          // Update the last e.clientY
          this.start = e.clientY

          // The next Vertical Value will be

          let next = this.scrolling + yMovementPercentage

          // Tell the parent to change the position
          this.onChangePosition(next, 'vertical')
        }
      },
      stopDrag(){ /*params: e*/
        if (this.dragging) {
          // Parent Should Change the Dragging State
          this.onStopDrag()
          this.dragging = false
        }
      },
      jump(e){
        let isContainer = e.target === this.$refs.container
        if (isContainer) {
          let position = this.$refs.scrollbar.getBoundingClientRect()

          // Calculate the vertical Movement
          let yMovement = e.clientY - position.top
          let centerize = (this.height / 2)
          let yMovementPercentage = yMovement / this.wrapper.height * 100 - centerize

          // Update the last e.clientY
          this.start = e.clientY

          // The next Vertical Value will be
          let next = this.scrolling + yMovementPercentage

          // Tell the parent to change the position
          this.onChangePosition(next, 'vertical')
        }
      },
      calculateSize(source){
        // Scrollbar Height
        this.height = (source.wrapper.height / source.area.height) * 100
      },
    },
    mounted() {
      this.calculateSize(this)
      document.addEventListener("mousemove", this.onDrag)
      document.addEventListener("touchmove", this.onDrag)
      document.addEventListener("mouseup", this.stopDrag)
      document.addEventListener("touchend", this.stopDrag)
    },
    beforeDestroy() {
      document.removeEventListener("mousemove", this.onDrag)
      document.removeEventListener("touchmove", this.onDrag)
      document.removeEventListener("mouseup", this.stopDrag)
      document.removeEventListener("touchend", this.stopDrag)
    },
    watch: {
      'scale' () {
        this.calculateSize(this)
      }
    },
  }
</script>