<template>
  <div :class="wrapperPageBgClass">
    <focus-loop>
      <div tabindex="0" class="skip-outline" id="pageBaseSkipFocus"></div>
      <slot></slot>
    </focus-loop>
  </div>
</template>

<script>

  import { FocusLoop } from '@vue-a11y/focus-loop'
  export default {
    name: "PageBase",
    components: { FocusLoop },
    props: {
      bgName: {
        type: String,
        required: false,
        default: 'game'
      },
    },
    data(){
      return {}
    },
    computed: {
      wrapperPageBgClass() {
        let classes = {
          'wrapper-page-bg': true,
        }
        classes[`page-bg-${this.bgName}`] = !!this.bgName

        return { ...classes }
      }
    },
  };
</script>

<style lang="stylus" scoped>
@import "~@/core/Styles/variables.styl"
@import "~@/core/Styles/mixins.styl"

.wrapper-page-bg
  width 100%
  height 100%
  background-color color-10
  background-position center
  background-repeat no-repeat
  background-size cover
  background-image url("~@/core/Assets/page_base/bg_game.jpg")
  &.page-bg
    &-game
      background-image url("~@/core/Assets/page_base/bg_game.jpg")
    &-login
      background-image url("~@/core/Assets/page_base/bg_login.jpg")

</style>
