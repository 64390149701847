import Vue from 'vue'
import Vuex from 'vuex'
import { ZoomModule, GameManagerModule } from '@/core/BaseStore'
import GameModule from '@/modules/Game/Store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { 
    ZoomModule: ZoomModule,
    GameModule: GameModule,
    GameManagerModule: GameManagerModule
  }
})
