<template>
  <div class="wrapper-modal">
  </div>
</template>

<script>

  export default {
    name: "Modal",
    components: { },
    props: {},
    data(){
      return {}
    },
    computed: {},
    mounted(){},
    destroyed() {},
  };
</script>

<style lang="stylus" scoped>
</style>
