<template>
  <modal-overlay ref="bakeResultOverlay">
      <div class="wrapper-big-box">
        <div class="wrapper-big-box__content">
          <div v-if="result">
            <img :src="pizzaCorrectImg" alt="Pizza correct" class="wrapper-big-box__content__img" tabindex="0">
            <button type="button" class="common-btn" @click="nextOrder()"
                    tabindex="0" :aria-label="$t('st_order_next')">{{ $t("st_order_next") }}</button>
          </div>
          <div v-else>
            <img :src="pizzaIncorrectImg" alt="Pizza incorrect" class="wrapper-big-box__content__img" tabindex="0">
            <button type="button" class="common-btn" @click="tryAgain()"
                    tabindex="0" :aria-label="$t('st_order_try_again')">{{ $t("st_order_try_again") }}</button>
          </div>
        </div>
      </div>
  </modal-overlay>
</template>

<script>

  import { ModalOverlay } from "@/core/Components"

  export default {
    name: "BakeResultModal",
    components: { ModalOverlay },
    props: {
      result: {
        type: Boolean,
        required: true,
        default: false
      }
    },
    data(){
      return {}
    },
    computed: {
      pizzaCorrectImg(){
        return this.getImageById('bake-pizza-correct')
      },
      pizzaIncorrectImg(){
        return this.getImageById('bake-pizza-incorrect')
      }
    },
    methods: {
      showOverlay(){
        this.$refs["bakeResultOverlay"].showOverlay()

        if(this.result){
          this.playPositiveAudio()
        }else{
          this.playNegativeAudio()
        }
      },
      hideOverlay(){
        this.$refs["bakeResultOverlay"].hideOverlay()
      },
      nextOrder(){
        this.$emit("onNextOrder")
        this.hideOverlay()
      },
      tryAgain(){
        this.$emit("onTryAgain")
        this.hideOverlay()
      },
      playPositiveAudio(){
        this.getAudioById('bake-pizza-sound-correct').play()
      },
      playNegativeAudio(){
        this.getAudioById('bake-pizza-sound-incorrect').play()
      }
    }
  };
</script>

<style lang="stylus" scoped>
@import "~@/core/Styles/variables.styl"
@import "~@/core/Styles/mixins.styl"

.wrapper-big-box
  flexbox(flex)
  justify-content(center)
  align-items(center)
  width 100%
  height 100%
  position relative
  &__content
    width 325px
    min-height 305px
    background-color color-10
    border 3px solid color-11
    padding 0
    text-align center
    border-radius(15px)
    box-shadow(big-box-shadow)
    position relative
    padding-bottom 30px
    &__img
      width 245px
      height 250px
      left 0
      position relative
      top -20px
    .common-btn
      display inline-block
      margin-top -20px
      height auto
      min-height 50px

</style>
