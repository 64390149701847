<template>
  <page-base>
    <div class="wrapper-game">
      <div class="wrapper-game__header">
        <order-form ref="pizzaOrderFormRef" @doAfterMakeClick="doAfterMakeClick"/>
        <game-header :game="gameData" :player="playerData"
                     @doOnNextPlayer="doOnNextPlayer" @doShownNextPlayerModal="doShownNextPlayerModal"/>
      </div>
      <div class="wrapper-game__pizza_options">
        <pizza-options-bar
          ref="pizzaOptionsBarRef"
          @onDragStart="onDragStart"
          @resetDropData="resetDropData"
          @openOrderForm="openOrderForm"
          :toppings-list="globalSetup.toppings"
          @onDragEnd="onDragEnd"
          @updateToppings="updateToppings"/>

        <pizza-drop-side
          ref="pizzaDropSideRef"
          @onDropOpen="onDropOpen"
          @onDropIncorrect="onDropIncorrect"
          @onDropCorrect="onDropCorrect"
          :item="pizzaDrop"
          v-model="activateDropArea"
          @onDragEnd="onDragEnd"
        />
      </div>
      <game-footer/>
    </div>
  </page-base>
</template>

<script>
  import { mapGetters } from "vuex";
  import { GameAppConstants } from "../Constants"
  import { DropData } from "../DataDragDrop"
  import { PageBase } from "@/core/Components"
  import { GameFooter, GameHeader, OrderForm, PizzaOptionsBar, PizzaDropSide } from "../Components"

  export default {
    name: "GameApp",
    components: { PageBase, GameFooter, GameHeader, OrderForm, PizzaOptionsBar, PizzaDropSide },
    props: {
      gameData: {
        type: Object,
        required: true,
        default: null
      },
      playerData: {
        type: Object,
        required: true,
        default: null
      },
      globalSetup: {
        type: Object,
        required: true,
        default: null
      },
    },
    data(){
      return {
        activateDropArea: false,
        pizzaDrop: DropData.PIZZA_DROP
      }
    },
    created () {},
    beforeMount () {
      this.newOrder()
    },
    computed: {
      ...mapGetters(["order", "userOrder"])
    },
    methods: {
      newOrder() {
        this.$store.dispatch('newOrder');
      },
      updateUserOrder(userOrder) {
        this.$store.dispatch('updateUserOrder', userOrder);
      },
      onDragStart(drag) {
        this.activateDropArea = true
        if(drag.isTopping){
          const $id = `checkboxContainer_${drag.idOrderOption}`
          const $checkboxContainer = document.getElementById($id)
          $checkboxContainer.classList.add('checkbox-hidden')
        }

        this.$nextTick(() => {
          this.$refs.pizzaDropSideRef.element.handleKeydown(drag.event)
        })
      },
      onDropOpen(){/*params: drop*/},
      onDragEnd(drag){
        if(drag.isTopping){
          const $id = `checkboxContainer_${drag.idOrderOption}`
          const $checkboxContainer = document.getElementById($id)
          $checkboxContainer.classList.remove('checkbox-hidden')
        }
      },
      onDropIncorrect() {
        this.activateDropArea = false
      },
      onDropCorrect(drop) {
        this.updateUserOrder(drop.drop.selectedOptions)
        if(drop.drop.drag.cheese){
          this.updateCurrentTab(GameAppConstants.PIZZA_TABS.TOPPINGS)
        }else if(drop.drop.drag.sauce){
          this.updateCurrentTab(GameAppConstants.PIZZA_TABS.CHEESE)
        } else if(drop.drop.drag.crust){
          this.updateCurrentTab(GameAppConstants.PIZZA_TABS.SAUCE)
        }

        const isToppingAndByCheckbox = drop.drop.currentDrag.isTopping === true && !drop.drop.byCheckbox
        const isToppingChildLeave = drop.drop.currentDrag.isToppingChild === true && drop.drop.currentDrag.leaved

        if(drop.drop.currentDrag && (isToppingAndByCheckbox) || isToppingChildLeave){
          const $id = `toppingCheckbox_${drop.drop.currentDrag.idOrderOption}`
          const $checkbox = document.getElementById($id)
          $checkbox.checked = !isToppingChildLeave
        }

        if(drop.isFinalized === true){
          this.disabledBakeButton(false)
        }else{
          this.disabledBakeButton(true)
        }

      },
      updateCurrentTab(tabId){
        this.$refs["pizzaOptionsBarRef"].updateCurrentTab(tabId)
      },
      resetDropData(){
        this.$refs["pizzaDropSideRef"].restartDropData()
      },
      doOnNextPlayer(){
        if(this.$refs["pizzaOrderFormRef"].orderFormIsOpen()){
          this.openOrderForm()
        }
        this.updateCurrentTab(GameAppConstants.PIZZA_TABS.CRUST)
        this.resetDropData()
        this.$refs["pizzaOptionsBarRef"].toggleTabs()
        this.$refs["pizzaOptionsBarRef"].unCheckToppings()
        this.disabledBakeButton(true)
      },
      openOrderForm(){
        this.$refs["pizzaOrderFormRef"].openOrderForm()
      },
      updateToppings(add, drag){
        this.$refs["pizzaDropSideRef"].updateDropZone(add, drag)
      },
      disabledBakeButton(isDisabled){
        this.$refs["pizzaOptionsBarRef"].setDisabledBakeBtn(isDisabled)
      },
      doAfterMakeClick(){
        this.$refs["pizzaOptionsBarRef"].showToppingsContent(true)
      },
      doShownNextPlayerModal(){
        this.$refs["pizzaOptionsBarRef"].hideBakeResultModal()
      }
    }
  };
</script>

<style lang="stylus" scoped>
  @import "../Styles/base_game.styl";
</style>
