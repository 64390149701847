export default {
  GAME_STATUS: {
    CREATED: 1,
    IN_PROCESS: 2
  },
  GAME_STEPS: {
    LOGIN: 1,
    LOBBY: 2,
    GAME: 3
  },
  GAME_MODE: {
    INDIVIDUAL: 1,
    SHARED: 2
  },  
  CRUST_OPTIONS: ["st_crust_oval", "st_crust_round", "st_crust_rectangular", "st_crust_square"],
  SAUCE_OPTIONS: ["st_sauce_tomato", "st_sauce_pesto", "st_sauce_white", "st_sauce_squid"],
  CHEESE_OPTIONS: ["st_cheese_mozzarella", "st_cheese_cheddar", "st_cheese_aged", "st_cheese_no"],
  NO_CHEESE: 3,
  WINNER_CONDITION: 1
}
