// simple stateful Directive to debounce Dom events
const DebounceEventDirective = (() => {
  let debounceTimeoutId;
  return {
    // Add Event Listener on mounted.
    bind(el, binding) {    
      // adding simple debounce pattern
      el.addEventListener(binding.arg, (e) => {
        clearTimeout(debounceTimeoutId)
        debounceTimeoutId = setTimeout(() => binding.value.method(e), binding.value.debounceTime)
      })
    },
  
    // Remove Event Listener on destroy.
    unbind(el, binding) {
      clearTimeout(debounceTimeoutId)
      el.removeEventListener(binding.arg, binding.value.method);
    }
  }
})()

export {
  DebounceEventDirective
}