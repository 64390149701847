import Vue from 'vue'

class DragAndDrop {
  currentDrag = null
  source = null
  top = null
  inProgress = false
  eventBus = new Vue()

  startDraggable(source, event, drag) {
    this.currentDrag = drag;
    this.source = source;
    this.inProgress = true;
    this._emit(event, "draggableStart")
  }

  moveDraggable(event) {
    this._emit(event, "draggableMove");
  }

  endDraggable(event) {
    const success = this.inProgress && !!this.top
    if (success) {
      this._emit(event, "droppableDrop");
    }

    this._emit(event, "draggableEnd")
    this.resetDraggable()
  }

  resetDraggable() {
    this.currentDrag = null
    this.source = null
    this.top = null
    this.inProgress = false
  }

  doDroppableOver(component) {
    if (this.inProgress) {
      this.top = component
    }
  }

  doDroppableLeave() {
    if (this.inProgress) {
      this.top = null
    }
  }

  _emit(native, event, data = {}) {
    this.eventBus.$emit(event, {
      top: this.top,
      source: this.source,
      native,
      ...data
    });
  }

  on(event, callback) {
    this.eventBus.$on(event, callback);
  }

  off(event, callback) {
    this.eventBus.$off(event, callback);
  }

  updateSourceDrag(obj) {
    this.source.drag = {...this.source.drag, ...obj}
  }
}

export let dnd = new DragAndDrop()

dnd = Vue.observable(dnd);
