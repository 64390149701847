<template>
  <modal-overlay ref="nextPlayerOverlay" :is-results-modal="true">
    <custom-modal-box class="results-box" :title='title' @closeAction="hideOverlay"
                      :class="{'non-winner-background': !isWinner, 'winner-background': isWinner, 'content-multiline': isMultiLine()}"
                      :box-content-styles="contentPizzaBoxStyle()">
        <div class="container-results-block">
            <div class="container-result-item" :style="correctPizzaBoxStyle()">
                <div class="completed-pizza-text" tabindex="0" :aria-label="getCorrectPizzasAriaLabel()">
                    <p class="label">{{ $t("st_result_correct_pizzas") }}</p>
                    <p class="global-countdown-numbers">{{ result.totalCompleted }}</p>
                </div>
            </div>
            <div class="container-result-item" :style="remakePizzaBoxStyle()">
                <div class="remake-pizza-text" tabindex="0" :aria-label="getRemakesAriaLabel()">
                    <p class="label">{{ $t("st_result_remakes") }}</p>
                    <p class="global-countdown-numbers">{{ result.totalRemake }}</p>
                </div>
            </div>
        </div>
        <div class="container-footer-results" v-if="isWinner">
            <div class="winner-pizza-text" v-if="isWinner" :style="winnerBoxStyle()">
                <div class="throphy-box" :style="throphyBoxStyle()"></div>
                <div class="winner-text" tabindex="0" :aria-label="$t('st_result_team_winner')">
                    {{ $t("st_result_team_winner") }}
                </div>
            </div>
        </div>
    </custom-modal-box>
  </modal-overlay>
</template>

<script>

  import { ModalOverlay, CustomModalBox } from "@/core/Components"
  import { GameConstants } from "@/core/Constants"

  export default {
    name: "TeamResultsModal",
    components: { ModalOverlay, CustomModalBox },
    props: {
      result: {
        type: Object,
        required: true
      },
    },
    data(){
      return {
      }
    },
    computed:{
      title(){
        return this.$t("st_result_team_title", {teamId: this.result.teamId})
      },
      isWinner(){
        return this.result.condition == GameConstants.WINNER_CONDITION
      }
    },
    methods: {
      showOverlay(){
        this.$refs["nextPlayerOverlay"].showOverlay()
      },
      hideOverlay(){
        this.$refs["nextPlayerOverlay"].hideOverlay()
        location.reload()
      },
      contentPizzaBoxStyle(){
        return {
          backgroundImage: `url(${this.getImageById('popup-result-first-place-team-bg')})`,
        }
      },
      winnerBoxStyle(){
        return {
          backgroundImage: `url(${this.getImageById('popup-result-first-place-team-banner')})`,
        }
      },
      throphyBoxStyle(){
        return {
          backgroundImage: `url(${this.getImageById('popup-result-first-place-team-throphy')})`,
        }
      },
      correctPizzaBoxStyle(){
        return {
          backgroundImage: `url(${this.getImageById('popup-result-pizza-correct')})`,
          backgroundPosition: 'left -20px'
        }
      },
      remakePizzaBoxStyle(){
        return {
          backgroundImage: `url(${this.getImageById('popup-result-pizza-remake')})`,
          backgroundPosition: 'right -20px'
        }
      },
      isMultiLine(){
        const text = this.$t("st_result_team_winner")
        return text ? text.length > 45 : false
      },
      getCorrectPizzasAriaLabel(){
        return `${this.$t("st_result_correct_pizzas")} ${this.result.totalCompleted}`
      },
      getRemakesAriaLabel(){
        return `${this.$t("st_result_remakes")} ${this.result.totalRemake}`
      }
    }
  };
</script>

<style lang="stylus" scoped>
</style>
