import { render, staticRenderFns } from "./GameFooter.vue?vue&type=template&id=080ea4fe&scoped=true&"
import script from "./GameFooter.vue?vue&type=script&lang=js&"
export * from "./GameFooter.vue?vue&type=script&lang=js&"
import style0 from "./GameFooter.vue?vue&type=style&index=0&id=080ea4fe&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "080ea4fe",
  null
  
)

export default component.exports