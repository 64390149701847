import GameFooter from "./GameFooter.vue"
import GameHeader from "./GameHeader.vue"
import OrderForm from "./OrderForm.vue"
import PizzaDropSide from "./PizzaDropSide.vue"
import PizzaOptionsBar from "./PizzaOptionsBar.vue"

export {
  GameFooter,
  GameHeader,
  OrderForm,
  PizzaOptionsBar,
  PizzaDropSide
}
