export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      drags: []
    }
  },
  methods: {
    onDragStart(drag) {
      this.$emit('onDragStart', drag)

      if (!drag.dragging && drag.event.type !== 'keydown') {
        setTimeout(() => {
          document.querySelector('.drop:not(.drop-dropzone-occupied)').focus()
        }, 200)
      }
    },
    onDragEnd(drag) {
      if (drag.dropped) {
        this.drags = this.drags.map(d =>
          d.id === drag.id ? Object.assign({}, d, drag) : d
        );
      }
      this.$emit('onDragEnd', drag)
    }
  },
  watch: {
    items: {
      immediate: true,
      handler: function (drags) {
        this.drags = drags
      }
    }
  },
  destroyed() {
    this.drags = []
  }
}
