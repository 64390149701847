import {dnd} from "../Core/DragAndDrop";
import DraggableAwareMixins from "./DraggableAwareMixins";

export default {
  mixins: [DraggableAwareMixins],
  props: {
    assistiveText: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: 'div'
    },
    acceptsData: {
      type: Function,
      default: () => true,
    }
  },
  data() {
    return {
      droppableId: null,
      drop: {},
      forceRecomputeCounter: 0
    }
  },
  computed: {
    dropIn() {
      if (this.draggableInProgress) {
        return this.draggableTop === this && !!this.draggableSource;
      } else {
        return false;
      }
    },
    cssClasses() {
      let clazz = {
        'drop': true
      }

      return {
        ...clazz,
        'drop-progress': !this.dropIn && this.draggableInProgress,
        'drop-zone': this.dropIn,
      }
    },
    attributeBindings() {
      let attributes = {
        ...this.$attrs,
        'role': 'link'
      }

      if (!this.dropIn && this.draggableInProgress) {
        attributes['tabindex'] = 0
        attributes['aria-dropeffect'] = 'move'
      }else{
        attributes['tabindex'] = "-1"
      }

      return attributes
    }
  },
  mounted() {
    this.droppableId = this._uid

    dnd.on('draggableMove', this.handleDraggableMove)
    dnd.on('droppableDrop', this.handleDroppableDrop)

    this.$el.addEventListener('keydown', this.handleKeydown)
    this.$el.addEventListener('click', this.handleOpen)
  },
  methods: {
    droppableAllowed(staticDrag) {
      this.forceRecomputeCounter;

      if(!!staticDrag && staticDrag.isTopping){
        return true
      }

      if (this.draggableInProgress) {
        return this.draggableSource.isDragging ?
          this.isDroppable() && this.effectiveAcceptsData(this.draggableData) :
          this.draggableSource.isSelected && this.effectiveAcceptsData(this.draggableData);
      } else {
        return false;
      }
    },
    handleDraggableMove() {
      if (this.isDroppable()) {
        dnd.updateSourceDrag({leaved: false})
        dnd.doDroppableOver(this)
        this.$emit('onDropOver')
      } else {
        if (this === this.draggableTop) {
          dnd.doDroppableLeave()
          dnd.updateSourceDrag({leaved: true})
          this.$emit("onDropLeave", dnd.source.drag)
        }
      }
    },
    handleDroppableDrop() {
      this.doDroppableDrop()
    },
    handleKeydown(e) {
      if (e.keyCode === 32) {
        e.preventDefault();
        dnd.doDroppableOver(this)
        this.draggableSource.handleUp(e)
      }
    },
    handleOpen(e) {
      e.preventDefault()
      if (this.drop.dropped) {
        this.$emit('onDropOpen', {...this.drop})
      }
    },
    doDroppableDrop(staticDrag, add) {
      this.forceRecomputeCounter++

      let draggableSource = staticDrag ? staticDrag : this.draggableSource.drag

      if(!!draggableSource && draggableSource.isToppingChild){
        if(draggableSource.leaved){
          this.updateOnDropCorrect(draggableSource, add)
        }
        return
      }

      if (this.draggableTop === this || (!!staticDrag && staticDrag.isTopping)) {
        const isDroppable = this.droppableAllowed(staticDrag)
        if (isDroppable) {
          this.updateOnDropCorrect(draggableSource, add)
        } else {
          this.$emit('onDropIncorrect', {...this.drop})
        }
      }
    },
    effectiveAcceptsData(draggableData) {
      return this.acceptsData(draggableData);
    },
    getOffset(element) {
      const offset = element.getBoundingClientRect();
      return {top: offset.top / this.draggableSource.scale, left: offset.left / this.draggableSource.scale}
    },
    isDroppable() {
      const offsetDrag = this.getOffset(this.draggableElement)
      const widthDrag = this.draggableElement.offsetWidth
      const heightDrag = this.draggableElement.offsetHeight

      const offsetDrop = this.getOffset(this.$el)
      const widthDrop = this.$el.offsetWidth
      const heightDrop = this.$el.offsetHeight;

      return !(offsetDrop.left > offsetDrag.left + widthDrag - widthDrag / 2 ||
        offsetDrop.left + widthDrop < offsetDrag.left + widthDrag / 2 ||
        offsetDrop.top > offsetDrag.top + heightDrag - heightDrag / 2 ||
        offsetDrop.top + heightDrop < offsetDrag.top + heightDrag / 2)
    },
    updateOnDropCorrect(draggableSource, add){
      let drag = this.drop.drag ? this.drop.drag : {}
      const byCheckbox = add !== null && add !== undefined

      if(draggableSource.isTopping === true || draggableSource.isToppingChild === true){

        let toppings = drag.toppings ? drag.toppings : {}
        if(byCheckbox && add === false){
          delete toppings[draggableSource.id]
        }else{
          toppings[draggableSource.id] = draggableSource
        }

        drag[draggableSource.pizzaTabKey] = toppings
      }else{
        drag[draggableSource.pizzaTabKey] = draggableSource
      }

      this.drop =  Object.assign({}, this.drop, {dropped: true, drag: drag, currentDrag: draggableSource, byCheckbox: byCheckbox})

      if(!byCheckbox){
        const $dragElem = document.getElementById(`${draggableSource.id}`)
        $dragElem.blur()
      }

      this.$emit('onDropCorrect', {...this.drop})
    }
  },
  watch: {
    item: {
      immediate: true,
      handler: function (drop) {
        this.drop = drop
      }
    }
  },
  destroyed() {
    dnd.off('draggableMove')
    dnd.off('droppableDrop')
  }
}
