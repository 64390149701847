import Vue from 'vue'
import VueI18n from "vue-i18n";
import { TranslationsService } from "@/core/Services"


function initI18n() {
  const lang = navigator.language || "en"

  Vue.use(VueI18n)

  return TranslationsService.getTranslations(lang).then(function(res) {
    return new VueI18n({
      locale: lang,
      fallbackLocale: lang,
      messages: {[lang]: res}
    })
  })
}

export default {
  initI18n
}
