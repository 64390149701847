import axios from "axios";
import Player from "./Player";

let GameManagerLoader = (function () {
  const getPathFile = (pathFilename) => {
    try {
      return require('@/' + pathFilename)
    } catch (e) {
      return null
    }
  }

  const getMedia = (filename) => {
    const fileMedia = getPathFile(filename)

    return {
      images: fileMedia.image || [],
      videos: fileMedia.video || [],
      audios: fileMedia.audio || []
    }
  }

  const checkImage = (image, externalImg) => {
    return new Promise((resolve, reject) => {
      const filename = externalImg ? image.src : getPathFile(image.src)

      const img = new Image()
      img.src = filename
      img.onload = async () => {
        image.src = img.src
        resolve(image)
      }
      img.onerror = reject
    });
  }

  const checkAudio = audio => {
    return new Promise((resolve) => {
      const filename = getPathFile(audio.src)

      audio.src = new Player(filename)
      resolve(audio)
    })
  }

  const checkVideo = media => {
    return new Promise((resolve, reject) => {
      const filename = getPathFile(media.src)

      axios.get(filename, {
        responseType: 'blob',
      }).then((response) => {
        const blob = new Blob([response.data]);
        const urlCreator = window.URL || window.webkitURL;

        media.src = urlCreator.createObjectURL(blob);

        resolve(media)
      }).catch(() => reject);
    })
  }

  const loadImage = (paths, externalImg) => {
    return Promise.all(paths.map((image) => checkImage(image, externalImg)))

  }
  const loadAudio = paths => Promise.all(paths.map(checkAudio))
  const loadVideo = paths => Promise.all(paths.map(checkVideo))


  class GameManagerLoader {

    constructor() {
      this.initial()
    }

    initial() {
      this.mediaSource = {
        images: [],
        audios: [],
        videos: []
      }
      this.dataSource = []

      this.media = {
        images: [],
        audios: [],
        videos: []
      }
      this.data = []
    }


    async load(resources) {
      try {

        if (!Object.keys(resources).length) {
          throw new Error("Activity Manager Failed");
        }

        this.moduleName = resources.moduleName.toLowerCase()
        this.mediaSource = getMedia(resources.media)

        const images = await loadImage(this.mediaSource.images)

        this.media['images'] = this.media['images'].concat(images)
        this.media['videos'] = await loadVideo(this.mediaSource.videos)
        this.media['audios'] = await loadAudio(this.mediaSource.audios)
      } catch (e) {
        throw new Error("Activity Manager Failed");
      }
    }

    async loadToppings(toppings) {
      try {
        if (!toppings || (toppings && toppings.length == 0)) {
          throw new Error("Preload toppings Failed");
        }
        const preloadToppings = await loadImage(toppings, true)

        this.media['images'] = this.media['images'].concat(preloadToppings)

      } catch (e) {
        throw new Error("Preload toppings Failed");
      }
    }

    getMediaById(type, mediaId) {
      let media = '';
      switch (type) {
        case 'image':
          media = this.media.images.find((image) => {
            return image.id === mediaId;
          })
          break;
        case 'audio':
          media = this.media.audios.find((audio) => {
            return audio.id === mediaId;
          })
          break;
        case 'video':
          media = this.media.videos.find((video) => {
            return video.id === mediaId;
          })
          break;
      }

      if (media === '' || media === undefined) {
        return "";
      }

      return media.src;
    }

    destroy() {
      this.initial()
    }
  }

  return GameManagerLoader
})()

const GameManager = new GameManagerLoader();

export default GameManager
