import { Game } from "@/core/Services"

const START_GAME_INTERVAL_MS = 250
let waitingForResponse = false

const GameHub = {
  install (Vue) {

    // use new Vue instance as an event bus
    const gameHub = new Vue({data: {userHasConnection: false}})

    gameHub.joinGame = (gameCode) => {
      const startGameInterval = setInterval(() => {
        if (!waitingForResponse) {
          waitingForResponse = true
          Game.startedAt(gameCode).then((response) => {
            if (response) {
              if (String(response) === 'Cancelled') {
                gameHub.$emit('game-reset')
              } else {
                gameHub.$emit('game-start', { gameStartedAt: response })
              }
              clearInterval(startGameInterval)
            }
            waitingForResponse = false
          }).catch(() => {
            waitingForResponse = false
          })
        }
      }, START_GAME_INTERVAL_MS)
    }

    // every component will use this.$gameHub to access the event bus
    Vue.prototype.$gameHub = gameHub
  }
};

export default GameHub;
