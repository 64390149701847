const NEW_ORDER = 'NEW_ORDER'
const NEW_USER_ORDER = 'NEW_USER_ORDER'
const USER_ORDER_CHANGED = 'USER_ORDER_CHANGED'
const START_GAME = 'START_GAME'
const MAKE_PIZZA = 'MAKE_PIZZA'
const INCREMENT_CORRECT_PIZZAS = 'INCREMENT_CORRECT_PIZZAS'
const INCREMENT_REMAKES_PIZZAS = 'INCREMENT_REMAKES_PIZZAS'

export {
  NEW_ORDER,
  NEW_USER_ORDER,
  USER_ORDER_CHANGED,
  START_GAME,
  MAKE_PIZZA,
  INCREMENT_CORRECT_PIZZAS,
  INCREMENT_REMAKES_PIZZAS
}
