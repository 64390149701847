function getPizzaGameData() {
  const user = localStorage.getItem('pizza_game') || '{}'
  return JSON.parse(user)
}

function setPizzaGameData(game) {
  localStorage.setItem('pizza_game', JSON.stringify(game))
}

function clearPizzaGameData() {
  localStorage.removeItem('pizza_game')
}

export default {
  getPizzaGameData,
  setPizzaGameData,
  clearPizzaGameData
}
