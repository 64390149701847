import axios from 'axios'

const JA_LOCALIZATION_URL = process.env.VUE_APP_JA_LOCALIZATION_URL;
const LOCALIZATION_API_KEY = process.env.VUE_APP_JA_LOCALIZATION_API_KEY;

function getLanguages(){
  return axios.get(`${JA_LOCALIZATION_URL}/api/language/`, {
    headers: {
      'API_KEY': LOCALIZATION_API_KEY,
      "Content-Type": "application/json",
    }
  }).then(response => response.data)
}

function getTranslations(lang) {
  return axios.get(`${JA_LOCALIZATION_URL}/api/translation/?culture_name=${lang}`, {
    headers: {
      'API_KEY': LOCALIZATION_API_KEY,
      "Content-Type": "application/json",
    }
  }).then(response => response.data)
}

export default {
  getTranslations,
  getLanguages
};