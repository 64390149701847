import {dnd} from "../Core/DragAndDrop";
import DraggableAwareMixins from "./DraggableAwareMixins";


export default {
  mixins: [DraggableAwareMixins],
  props: {
    assistiveText: {
      type: String,
    },
    item: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: 'div'
    },
    scale: {
      type: Number,
      default: 1,
    },
    z: {
      type: [String, Number],
      default: 'auto'
    },
    availableClone: false
  },
  data() {
    return {
      draggableId: null,
      drag: {},
      left: 0,
      top: 0,
      isSelected: false,
      isDragging: false,
      zIndex: this.z,
      $dragElem: null,
      isCloned: false,
      $clonedElem: null,
      $containerClones: null
    }
  },
  computed: {
    dragIn() {
      return !this.draggableInProgress && !(this.dragging || this.isSelected);
    },
    style() {
      return this.getStyles()
    },
    cssClasses() {
      return this.getCssClasses()
    },
    attributeBindings() {
      return this.getAttributeBindings()
    }
  },
  mounted() {
    this.resetMouseState()
    this.draggableId = this._uid

    this.$containerClones = document.getElementById("containerCloneDrags")

    const $element = this.$el
    this.$dragElem = $element

    this.$dragElem.addEventListener('mousedown', this.handleDragStart)
    this.$dragElem.addEventListener('touchstart', this.handleDragStart)
    this.$dragElem.addEventListener('keydown', this.handleKeydown)
  },
  methods: {
    getStyles(){
      let css = {
        zIndex: this.draggableInProgress && this.draggableSource === this && !this.dragIn ? this.zIndex : 'auto',
        transform: `translate(${this.left}px, ${this.top}px)`
      }
      return css
    },
    getAttributeBindings(){
      let attributes = {
        ...this.$attrs,
        'role': 'link'
      }

      return {
        ...attributes,
        'aria-grabbed': !this.dragIn ? 'true' : 'false',
        'tabindex': this.drag.dropped || this.drag.isToppingChild ? -1 : 0
      }
    },
    getCssClasses(){
      let clazz = {
        'drag': true
      }

      return {
        ...clazz,
        'drag-in': this.dragIn,
        'drag-out': this.draggableInProgress && this.draggableSource === this && !this.dragIn,
        'drag-source': this.draggableInProgress && this.draggableSource === this,
      }
    },
    setCloneStyles(){
      const originalPosition = this.$dragElem.getBoundingClientRect()

      this.$clonedElem.style.zIndex = this.getStyles().zIndex
      this.$clonedElem.style.transform =`translate(-8px, -1px)`
      this.$clonedElem.style.width = `${originalPosition.width}px`
      this.$clonedElem.style.height = `${originalPosition.height}px`
      this.$clonedElem.style.left = `${originalPosition.x}px`
      this.$clonedElem.style.top = `${originalPosition.y}px`
    },
    handleDragStart(e) {
      const target = e.target || e.srcElement

      if (this.$dragElem.contains(target) && !this.drag.dropped) {
        this.mouseClickPosition.mouseX = e.touches ? e.touches[0].pageX : e.pageX
        this.mouseClickPosition.mouseY = e.touches ? e.touches[0].pageY : e.pageY

        this.mouseClickPosition.top = this.top
        this.mouseClickPosition.left = this.left

        this.isSelected = true
        this.isDragging = e.type !== 'keydown'
        this.drag = Object.assign({}, this.drag, {dragging: this.isDragging})

        dnd.startDraggable(this, e, this.drag)
        this.$emit('onDragStart', {...this.drag, event: e})

        if (e.type !== 'keydown') {
          document.documentElement.addEventListener('mousemove', this.handleDrag, true)
          document.documentElement.addEventListener('mouseup', this.handleUp, true)

          document.documentElement.addEventListener('touchmove', this.handleDrag, true)
          document.documentElement.addEventListener('touchend', this.handleUp, true)

          if(this.availableClone === true){
            this.addCloneDrag()
            this.setCloneStyles()
            this.$dragElem.style.opacity = 0
          }

        }
      }
    },
    handleDrag(e) {
      if (this.draggableInProgress) {
        const pageX = (e.touches ? e.touches[0].pageX : e.pageX)
        const pageY = (e.touches ? e.touches[0].pageY : e.pageY)

        const delta = {
          x: (this.mouseClickPosition.mouseX - pageX) / this.scale,
          y: (this.mouseClickPosition.mouseY - pageY) / this.scale
        }

        const left = this.mouseClickPosition.left - delta.x
        const top = this.mouseClickPosition.top - delta.y

        this.left = left
        this.top = top

        if(this.availableClone === true){
          if(this.isCloned){
            this.setCloneStyles()
          }
        }

        dnd.moveDraggable(e)
        this.$emit('onDrag', {drag: this.drag, left: this.left, top: this.top})
      }
    },
    handleUp(e) {
      dnd.endDraggable(e)
      this.resetMouseState()

      if(this.availableClone === true){
        this.isCloned = false
        this.$containerClones.innerHTML = ""
        this.$dragElem.style.opacity = 1
      }

      this.$emit('onDragEnd', {...this.drag}, e)

      document.documentElement.removeEventListener('mousemove', this.handleDrag, true)
      document.documentElement.removeEventListener('mouseup', this.handleUp, true)

      document.documentElement.removeEventListener('touchmove', this.handleDrag, true)
      document.documentElement.removeEventListener('touchend', this.handleUp, true)
    },
    handleKeydown(e) {
      if (e.keyCode === 32) {
        e.preventDefault();
        this.handleDragStart(e)
      }
    },
    resetMouseState() {
      this.isDragging = false
      this.mouseClickPosition = {mouseX: 0, mouseY: 0, left: 0, top: 0}
      this.isSelected = false
      this.left = 0
      this.top = 0
    },
    addCloneDrag(){

      const $cloneDragElem = this.$dragElem.cloneNode(false)
      $cloneDragElem.classList.add("drag-cloned")

      this.$clonedElem = $cloneDragElem
      this.$containerClones.appendChild(this.$clonedElem)
      this.isCloned = true
    }
  },
  watch: {
    item: {
      immediate: true,
      handler: function (drag) {
        this.drag = Object.assign({}, drag, {attempts: 0})
      }
    },
    z(val) {
      if (val >= 0 || val === 'auto') {
        this.zIndex = val
      }
    }
  },
  destroyed() {
    this.resetMouseState()
    dnd.resetDraggable()
    dnd.off('draggableStart')
    dnd.off('draggableEnd')
  }
}
