import { NEW_ORDER, NEW_USER_ORDER, USER_ORDER_CHANGED, 
         MAKE_PIZZA, INCREMENT_CORRECT_PIZZAS, INCREMENT_REMAKES_PIZZAS } from "@/modules/Game/Store/mutation-types";
import { PizzaOrder } from "@/core/Helpers";
import { Game } from "@/core/Services"
import { Storage } from "@/core/Utils"


function updateUserScore(state) {
  const localGame = Storage.getPizzaGameData()
  if(localGame.player){
    const data = {
      "Player": localGame.player,
      "TotalCompleted": state.correctPizzas,
      "TotalRemake": state.remakesPizzas,
    }
    Game.updateScore(data).then(() => {
    })
  }
}

export const newOrder = ({commit}) => {
  const _order = new PizzaOrder()
  const order = {
    ids: _order.getOrder(),
    texts: _order.getOrderText()
  }

  commit(NEW_ORDER, order)
}

export const makePizza = ({commit}) => {
  commit(MAKE_PIZZA)
}

export const incrementCorrects = ({commit, state}) => {
  commit(INCREMENT_CORRECT_PIZZAS)
  updateUserScore(state)
}

export const incrementRemakes = ({commit, state}) => {
  commit(INCREMENT_REMAKES_PIZZAS)
  updateUserScore(state)
}

export const createUserOrder = ({commit}) => {
  commit(NEW_USER_ORDER)
}

export const updateUserOrder = ({commit}, userOrder) => {
  commit(USER_ORDER_CHANGED, userOrder)
}
