<template>
  <component :class="cssClasses"
             :style="style"
             :is="tag"
             v-bind="attributeBindings"
             v-on="$listeners"
            :aria-label="getDragAriaLabel()">
    <slot></slot>
  </component>
</template>

<script>
import DraggableMixin from "../mixins/DraggableMixin";

export default {
  name: "Draggable",
  mixins: [DraggableMixin],
  methods: {
    getDragAriaLabel(){
      return this.$t("st_drags_aria_label", {dragOption: this.assistiveText})
    }
  }
}
</script>

<style lang="stylus" scoped>
.drag
  touch-action: none;
  position: relative
  box-sizing: border-box;
  cursor grab
  cursor -moz-grab
  cursor -webkit-grab
  user-select auto
  &-out
    user-select none
    cursor -webkit-grabbing
    cursor    -moz-grabbing
    cursor         grabbing
  &-in
    transition transform 0.4s linear

  &-cloned
    background-repeat no-repeat
    background-position center
    background-size 80%
    width 150px
    height 145px
    z-index 1
    position absolute
    display inline-block

</style>
