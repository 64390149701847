<template>
  <main-page-base id="app">
    <router-view/>
  </main-page-base>
</template>

<script>
  import MainPageBase from "./core/Components/PageBase/MainPageBase.vue"

  export default {
    components: { MainPageBase },
  }
</script>
